import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const statusArr = [
  {
    label: "Approved",
    value: 1,
  },
  {
    label: "Rejected",
    value: 2,
  },
  {
    label: "Pending",
    value: 0,
  },
];

const CustomAlert = ({ status, path }) => {
  const navigate = useNavigate();

  // Check if the vendor's status is pending
  if (status === "Pending") {
    return (
      <Stack
        sx={{
          border: "1px solid #ff9800", // Warning color border
          borderRadius: "8px",
          padding: "6px 10px",
          backgroundColor: "#fff8e1", // Light warning background color
        }}
      >
        <Typography
          sx={{ fontWeight: "500", color: "#ff9800", fontSize: "16px" }}
        >
          Approval Required
        </Typography>

        <Typography
          variant="caption"
          sx={{ color: "#6a6a6a", fontSize: "14px" }}
        >
          You cannot access any features until your account is approved by the
          admin. Your status is currently pending.{" "}
          <Typography
            onClick={() => navigate(path)}
            variant="caption"
            sx={{ color: "#ff9800", fontSize: "14px", cursor: "pointer" }}
          >
            {" "}
            Click here{" "}
          </Typography>
          to view your current status
        </Typography>
        {/* <Button
          variant="outlined"
          sx={{ marginTop: "16px", borderColor: "#ff9800", color: "" }}
        ></Button> */}
      </Stack>
    );
  } else if (status === "Rejected") {
    return (
      <Stack
        sx={{
          border: "1px solid #d32f2f", // Red border for rejection
          borderRadius: "8px",
          padding: "10px",
          backgroundColor: "#ffebee", // Light red background
        }}
      >
        <Typography
          sx={{ fontWeight: "500", color: "#d32f2f", fontSize: "16px" }}
        >
          Account Rejected
        </Typography>

        <Typography
          variant="caption"
          sx={{ color: "#6a6a6a", fontSize: "14px" }}
        >
          Your account request has been **rejected** by the admin. You will not
          be able to access any features.
          <Typography
            onClick={() => navigate(path)}
            variant="caption"
            sx={{ color: "#d32f2f", fontSize: "14px", cursor: "pointer" }}
          >
            Click here
          </Typography>{" "}
          to view the status.
        </Typography>
      </Stack>
    );
  }

  return null; // No alert if status is approved or any other status
};

export default CustomAlert;
