import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { themeOrange } from "../../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidCalendar, BiSolidLock } from "react-icons/bi";
import { inputDateFormate } from "../../../utils/CustomFormator";
import { MdLocationOn, MdWatchLater } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import Input from "../../common/Input";
import { asyncStatus } from "../../../utils/asyncStatus";
import { useNavigate } from "react-router-dom";
import { PostReviewAsync } from "../../../services/services";
import { imgUrl } from "../../../utils/imageUrl";
import { format } from "date-fns";
import { LuCalendarClock } from "react-icons/lu";
import CustomModal from "../../common/CustomModal/CustomModal";
import secure_check from "../../../assets/secure_check.png";

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { error_toast_message, success_toast_message } from "../../../utils/toast_message";
import CustomButton from "../../common/Button/Button";
import { loadStripe } from "@stripe/stripe-js";
import { RxCross2 } from "react-icons/rx";
import { baseURL } from "../../../config/apiHandle/apiHandle";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const CompletedJobsTabWithoutPayment = () => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobReview, setjobReview] = useState({});
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [reloadKey, setReloadKey] = useState(0);  // Add this state

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { get_all_jobs_data, post_review_status } = useSelector(
    (state) => state.serviceManage
  );

  const completedJobs = get_all_jobs_data?.filter(
    (job) => job.status === "Completed" || job.status === "Cancelled"
  );

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  const handleViewDetails = (jobId) => {
    setSelectedJobId((prevId) => (prevId === jobId ? null : jobId));
  };

  const ratingChanged = (newRating) => {
    setjobReview({ ...jobReview, rating: newRating });
  };

  const handlePostReview = (elm) => {
    let obj = {
      msg: jobReview.msg,
      rating: jobReview.rating,
      oid: elm,
    };
    dispatch(PostReviewAsync(obj));
    setjobReview({
      msg: "",
      rating: "",
    });
  };

  const handlePaymentSubmit = async () => {
    if (!stripe || !elements) {
      error_toast_message("Stripe has not loaded yet. Please try again.");
      return;
    }

    setPaymentProcessing(true);

    try {
      // Get the CardElement
      const cardElement = elements.getElement(CardElement);

      // Find the job to get payment details
      const job = completedJobs.find(job => job.id === selectedJobId);
      if (!job) {
        error_toast_message("Job not found");
        setPaymentProcessing(false);
        return;
      }

      // Calculate payment amount
      const addOnPrices = job?.add_ons
        ? JSON.parse(job.add_ons).reduce((sum, item) => sum + item.price, 0)
        : 0;
      const subtotal = addOnPrices;
      const taxRate = 0.13;
      const taxableAmount = subtotal;
      const tax = taxableAmount * taxRate;
      const total = taxableAmount + tax;

      // Convert to cents (Stripe uses smallest currency unit)
      const amountInCents = Math.round(total * 100);

      // Step 1: Create Payment Intent on your backend
      const { data: paymentIntent } = await axios.post(
        `${baseURL}/stripe/create-payment-intent`,
        {
          amount: amountInCents,
          currency: 'cad',
          description: `Payment for ${job.service?.name || 'service'}`,
          order_id: selectedJobId,
          customer_email: job.vendor?.email || '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('auth')}`
          }
        }
      );
      setIsOpenModal(false);
      setPaymentProcessing(false);
      setReloadKey(prev => prev + 1);  // This will force component to reload

      // Step 2: Confirm the payment on the client side
      const { error, paymentIntent: confirmedIntent } = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: job.vendor?.first_name + ' ' + job.vendor?.last_name || 'Customer',
              email: job.vendor?.email || '',
            },
          },
        }
      );

      if (error) {
        error_toast_message(error.message);
        setPaymentProcessing(false);
        return;
      }

      if (confirmedIntent.status === 'succeeded') {
        success_toast_message("Payment successful!");
        setIsOpenModal(false);
        // Optionally refresh data or update local state
      }
      setPaymentProcessing(false);
    } catch (error) {
      //error_toast_message(error.response?.data?.message || error.message || "An error occurred during payment");
    } finally {
      setPaymentProcessing(false);
    }
  };


  // const handlePaymentSubmit = async () => {
  //   if (!stripe || !elements) {
  //     error_toast_message("Stripe has not loaded yet. Please try again.");
  //     return;
  //   }

  //   setPaymentProcessing(true);

  //   try {
  //     // Get the CardElement
  //     const cardElement = elements.getElement(CardElement);

  //     // Create payment method
  //     const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: cardElement,
  //     });

  //     if (paymentMethodError) {
  //       error_toast_message(paymentMethodError.message);
  //       setPaymentProcessing(false);
  //       return;
  //     }

  //     // Find the job to get payment details
  //     const job = completedJobs.find(job => job.id === selectedJobId);
  //     if (!job) {
  //       error_toast_message("Job not found");
  //       setPaymentProcessing(false);
  //       return;
  //     }

  //     // Calculate payment amount
  //     const addOnPrices = job?.add_ons
  //       ? JSON.parse(job.add_ons).reduce((sum, item) => sum + item.price, 0)
  //       : 0;
  //     const subtotal = addOnPrices;
  //     const taxRate = 0.13;
  //     const taxableAmount = subtotal;
  //     const tax = taxableAmount * taxRate;
  //     const total = taxableAmount + tax;

  //     // Convert to cents (Stripe uses smallest currency unit)
  //     const amountInCents = Math.round(total * 100);

  //     // Prepare payment data
  //     const paymentData = {
  //       payment_method: paymentMethod.id,
  //       amount: amountInCents,
  //       currency: 'usd',
  //       jobId: selectedJobId,
  //       description: `Payment for ${job.service?.name || 'service'}`,
  //     };

  //     // Call your backend API directly from here
  //     const response = await axios.post('/api/payments/process', paymentData, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${localStorage.getItem('token')}` // Add auth token if needed
  //       }
  //     });

  //     if (response.data.success) {
  //       success_toast_message("Payment successful!");
  //       setIsOpenModal(false);
  //       // Optionally refresh job data here or update local state
  //     } else {
  //       error_toast_message(response.data.message || "Payment failed");
  //     }
  //   } catch (error) {
  //     error_toast_message(error.response?.data?.message || error.message || "An error occurred during payment");
  //   } finally {
  //     setPaymentProcessing(false);
  //   }
  // };

  if (!completedJobs?.length) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ height: "60vh" }}>
        <Typography>No Completed Jobs Found</Typography>
      </Stack>
    );
  }

  return (
    <Stack sx={{ width: "100%", backgroundColor: "white", borderRadius: "15px" }} gap={2} key={reloadKey}>
      {completedJobs
        ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        ?.map((job) => {
          const isSelected = selectedJobId === job?.id;
          const isStatusCancelled = job?.status === "Cancelled";
          const addOnPrices = job?.add_ons
            ? JSON.parse(job.add_ons).reduce((sum, item) => sum + item.price, 0)
            : 0;
          const subtotal = addOnPrices;
          const taxRate = 0.13;
          const taxableAmount = subtotal;
          const tax = taxableAmount * taxRate;
          const total = taxableAmount + tax;

          return (
            <Stack key={job?.id} sx={{ width: "100%", backgroundColor: "white", borderRadius: "15px" }} >
              {/* Job Header */}
              <Stack className="globleGradientBlue" sx={{ borderRadius: "15px", py: 1.5, px: 2, boxShadow: "none" }}>
                <Typography className="secondSubHeading" sx={{ color: "white" }}>
                  {job?.service?.name || ""}
                </Typography>
              </Stack>

              {/* Job Summary */}
              <Stack direction="row" alignItems="center" sx={{ px: 2, py: 2 }}>
                <Stack direction="row" alignItems="flex-start" gap={2} sx={{ width: "100%" }}>
                  <Stack pb={1.5} gap={1} sx={{ borderRadius: "10px", width: "150px", backgroundColor: "#F5F5F5", flexShrink: "0", overflow: "hidden" }}>
                    <Stack sx={{ height: "70px", width: "100%" }}>
                      <img
                        src={`${imgUrl}${job?.service?.mobile_image}`}
                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        alt={job?.service?.name}
                      />
                    </Stack>
                    <Typography className="mainPara" sx={{ color: "#333333", mx: 1, textAlign: "center" }}>
                      {job?.service?.name || ""}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                    flexWrap={{ xl: "nowrap", lg: "nowrap", md: "wrap", sm: "wrap", xs: "wrap" }}
                  >
                    <Stack gap={0.5}>
                      {/* Date and Time */}
                      <Stack sx={{ height: "100%" }}>
                        {Array.isArray(job?.date) &&
                          job?.date?.map(({ selected_date, shifts }) => (
                            <Stack mt={0.5} flexDirection="row" alignItems="center" gap={2}>
                              <LuCalendarClock style={{ color: themeOrange, fontSize: "26px", flexShrink: "0" }} />
                              <Typography className="subpara" sx={{ color: "#333333" }}>
                                {format(selected_date, "EEE, MMM d, yyyy")}
                              </Typography>
                              <Typography className="service_shifts_txt">
                                ({shifts?.map((shift) => shift?.slice(0, 3)).join(", ")})
                              </Typography>
                            </Stack>
                          ))}
                      </Stack>

                      {/* Time */}
                      {job?.time && (
                        <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" gap={1}>
                          <MdWatchLater style={{ fontSize: "24px", color: "#F15A24", flexShrink: 0 }} />
                          <Typography className="subpara" sx={{ color: "#333333" }}>
                            {job?.time?.length > 30 ? `${job?.time?.substring(0, 30)}...` : job?.time}
                          </Typography>
                        </Stack>
                      )}

                      {/* Location */}
                      {job?.location && (
                        <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" gap={1}>
                          <MdLocationOn style={{ fontSize: "24px", color: "#F15A24", flexShrink: 0 }} />
                          <Typography className="subpara" sx={{ color: "#333333" }}>
                            {job?.location}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>

                    {/* Status and View Details Button */}
                    <Stack justifyContent="space-between" alignItems="flex-end" sx={{ height: "100%" }} gap={5}>
                      <Typography
                        sx={{
                          color: "white",
                          backgroundColor: isStatusCancelled ? "red" : "green",
                          padding: "5px 10px",
                          borderRadius: "8px",
                          fontSize: "12px",
                        }}
                      >
                        {job.status}
                      </Typography>
                      {job?.payments_status == 'Released' &&
                        <Typography
                          sx={{
                            color: "white",
                            backgroundColor: isStatusCancelled ? "red" : "green",
                            padding: "5px 10px",
                            borderRadius: "8px",
                            fontSize: "12px",
                          }}
                        >
                          Paid
                        </Typography>
                      }
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "#F15A24",
                          color: "#F15A24",
                          borderRadius: "15px",
                          px: 3,
                          py: 1,
                          backgroundColor: "white",
                          ":hover": {
                            backgroundColor: "white",
                            borderColor: "#F15A24",
                          },
                          textTransform: "none",
                          mt: 1,
                        }}
                        onClick={() => handleViewDetails(job?.id)}
                      >
                        {isSelected ? "Hide Detail" : "View Detail"}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              {/* Job Details (Expanded View) */}
              {isSelected && (
                <Stack sx={{ width: "100%", backgroundColor: "white", borderRadius: "15px", px: 1 }}>
                  {/* Job Description */}
                  <Stack gap={1} px={2} py={2}>
                    <Typography className="mainHeading" sx={{ color: "#333333", fontSize: "20px" }}>
                      Job Description
                    </Typography>
                    <Stack sx={{ borderRadius: "15px", border: "1px solid #F1F1F1" }} px={2} py={2}>
                      <Typography className="mainPara" sx={{ color: "#868181" }}>
                        {job?.description || ""}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* Invoice Section */}
                  <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, maxWidth: 800, margin: "auto", backgroundColor: "#fff", mb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="h4" sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                            Invoice
                          </Typography>
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              Date: {new Date().toLocaleDateString()}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Invoice #: INV-{Math.floor(1000 + Math.random() * 9000)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mb: 3 }} />

                    <Box sx={{ mb: 4 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">Service Amount:</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle1">${(2).toFixed(2)}</Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    {/* Items Table */}
                    <TableContainer sx={{ mb: 2 }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                            <TableCell sx={{ fontWeight: "bold" }}>Additional Items</TableCell>
                            <TableCell sx={{ fontWeight: "bold", textAlign: "right" }}>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {job?.add_ons && JSON.parse(job.add_ons).map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.name}</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>${item.price.toFixed(2)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* Calculations Section */}
                    <Grid container spacing={1} sx={{ maxWidth: 300, ml: "auto", mt: 2 }}>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                          Additional Total Amount:
                        </Typography>
                        <Typography variant="subtitle1">HST (13%):</Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Total:
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">${subtotal.toFixed(2)}</Typography>
                        <Typography variant="subtitle1">${tax.toFixed(2)}</Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold", color: theme.palette.success.main }}>
                          ${total.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="body2" sx={{ fontStyle: "italic", textAlign: "center" }}>
                      Thank you for your business. Please make payments payable to [Company Name]
                      <br /> Payment due within 30 days of invoice date
                    </Typography>
                  </Paper>
                  {console.clear()}
                  {console.log(job?.payments_status)}
                  {/* Payment Buttons */}
                  {job?.payments_status !== 'Released' &&
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={5}>
                      <CustomButton
                        onClick={() => setIsOpenModal(!isOpenModal)}
                        style={{ background: "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))" }}
                      >
                        Pay Invoice
                      </CustomButton>
                    </Stack>
                  }
                </Stack>
              )}
            </Stack>
          );
        })}

      {/* Payment Modal */}
      <CustomModal isOpen={isOpenModal} setIsOpen={() => setIsOpenModal(!isOpenModal)}>
        <Stack sx={{
          overflow: "auto",
          backgroundColor: "white",
          borderRadius: { xs: "8px", sm: "16px" },
          boxShadow: 24,
          width: { xs: "95vw", sm: "500px", md: "600px" },
          maxWidth: "100%",
          maxHeight: "90vh",
          position: "relative",
          p: { xs: 2, sm: 3, md: 4 },
        }}>
          <IconButton
            onClick={() => setIsOpenModal(false)}
            sx={{
              position: "absolute",
              right: { xs: 8, sm: 16 },
              top: { xs: 8, sm: 16 },
              p: { xs: 0.5, sm: 1 },
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "action.hover",
                transform: "scale(1.1)",
              },
              transition: "all 0.2s ease",
            }}
          >
            <RxCross2 size={20} />
          </IconButton>

          <Stack gap={{ xs: 2, sm: 3 }} width="100%">
            <Typography variant="h5" fontWeight="600" color="text.primary" fontSize={{ xs: "1.5rem", sm: "1.75rem" }}>
              Confirm Payment
            </Typography>

            <Stack gap={{ xs: 2, sm: 3 }} width="100%">
              <Typography variant="body1" color="text.secondary" fontSize={{ xs: "0.875rem", sm: "1rem" }}>
                Please verify your payment details
              </Typography>

              <Stack gap={2}>
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <BiSolidLock style={{
                    fontSize: { xs: "24px", sm: "28px" },
                    color: themeOrange,
                    flexShrink: 0,
                  }} />
                  <Typography variant="h6" fontWeight="500" fontSize={{ xs: "1.1rem", sm: "1.25rem" }}>
                    Credit Card Information
                  </Typography>
                </Stack>

                <CardElement
                  onChange={handleCardChange}
                  className="stripeInput"
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />

                {isCardComplete && (
                  <Stack direction="row" alignItems="center" gap={1} mt={1}>
                    <img
                      src={secure_check}
                      style={{
                        height: { xs: "20px", sm: "24px" },
                        width: { xs: "20px", sm: "24px" },
                        objectFit: "contain",
                      }}
                      alt="Secure"
                    />
                    <Typography variant="caption" color="text.secondary" fontSize={{ xs: "0.75rem", sm: "0.85rem" }}>
                      256-bit SSL secured payment
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack direction="row" justifyContent="flex-end" gap={2} mt={{ xs: 2, sm: 4 }}>
                <CustomButton
                  variant="contained"
                  onClick={handlePaymentSubmit}
                  disabled={!isCardComplete || paymentProcessing}
                  fullWidth={{ xs: true, sm: false }}
                  sx={{
                    background: "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                    color: "white",
                    px: { xs: 2, sm: 4 },
                    py: { xs: 1, sm: 1.5 },
                    borderRadius: "6px",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                    minWidth: { xs: "100%", sm: "140px" },
                    "&:hover": {
                      opacity: 0.9,
                      transform: { xs: "none", sm: "scale(1.02)" },
                    },
                    transition: "all 0.2s ease",
                    "&:disabled": {
                      background: "#cccccc",
                    }
                  }}
                >
                  {paymentProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Confirm Payment"
                  )}
                </CustomButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
    </Stack>
  );
};

const CompletedJobsTab = () => (
  <Elements stripe={stripePromise}>
    <CompletedJobsTabWithoutPayment />
  </Elements>
);

export default CompletedJobsTab;