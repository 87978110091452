import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  GetAllJobsAsync,
  JobGetByVendorAsync,
  PostReviewAsync,
  acceptJobAsync,
  getJobsAsync,
  getPaymentRequestDataAsync,
  getVendorDataAsync,
  get_service_by_id,
  get_services,
  jobPostAsync,
  searchServiceAsync,
  updateJobStatusAsync,
  GetJobByIdAsync,
} from "../../services/services";

const initialState = {
  //   =============>>>>>>>  Service By Id
  get_service_by_id_status: asyncStatus.IDLE,
  get_service_by_id_data: null,
  get_service_by_id_error: null,

  //   =============>>>>>>>  Job By Id
  get_job_by_id_status: asyncStatus.IDLE,
  get_job_by_id_data: null,
  get_job_by_id_error: null,

  //   =============>>>>>>>  Home Main Service
  get_all_services_status: asyncStatus.IDLE,
  get_all_services_data: null,
  get_all_services_error: null,

  //   =============>>>>>>>  Service Job Post
  post_job_service_status: asyncStatus.IDLE,
  post_job_service_data: null,
  post_job_service_error: null,

  //   =============>>>>>>>  Get Jobs
  get_jobs_status: asyncStatus.IDLE,
  get_jobs_data: null,
  get_jobs_error: null,

  //   =============>>>>>>>  Accept Jobs
  accept_job_status: asyncStatus.IDLE,
  accept_job_data: null,
  accept_job_error: null,

  //   =============>>>>>>>  get Jobs by vendor
  get_job_by_vendor_status: asyncStatus.IDLE,
  get_job_by_vendor_data: null,
  get_job_by_vendor_error: null,

  // =========>>>>>>> Update Job Status By Id
  get_payment_request_data: null,
  get_payment_request_status: asyncStatus.IDLE,
  get_payment_request_error: null,

  //   =============>>>>>>>  get all jobs
  get_all_jobs_status: asyncStatus.IDLE,
  get_all_jobs_data: null,
  get_all_jobs_error: null,

  //   =============>>>>>>>  job_status_update
  job_status_update_status: asyncStatus.IDLE,
  job_status_update_data: null,
  job_status_update_error: null,

  //   =============>>>>>>>  search_service
  search_service_status: asyncStatus.IDLE,
  search_service_data: null,
  search_service_error: null,

  //   =============>>>>>>>  post_review
  post_review_status: asyncStatus.IDLE,
  post_review_data: null,
  post_review_error: null,

  //   =============>>>>>>>  post_review
  get_vendor_status: asyncStatus.IDLE,
  get_vendor_data: null,
  get_vendor_error: null,

  //   =============>>>>>>>  Job Posts Images
  jobs_images: [],
};

const services_manage_slice = createSlice({
  name: "serviceManage",
  initialState,

  reducers: {
    setIdleStatus(state) {
      state.user_logout_status = asyncStatus.IDLE;
    },
    setUserRole(state, { payload }) {
      state.role = payload;
    },
    setpageValue(state, { payload }) {
      state.pageValue = payload;
    },

    setJobPostStatus(state) {
      state.post_job_service_status = asyncStatus.IDLE;
    },
    setJobAcceptStatus(state) {
      state.accept_job_status = asyncStatus.IDLE;
    },
    setJobsImages(state, { payload }) {
      console.log("payload images", payload);
      state.jobs_images = payload;
    },
  },
  extraReducers: (builder) => {
    //  Get Services By Id =========>>>>>>>>>>>
    builder.addCase(get_service_by_id.pending, (state, action) => {
      state.get_service_by_id_status = asyncStatus.LOADING;
    });

    builder.addCase(get_service_by_id.fulfilled, (state, { payload }) => {
      state.get_service_by_id_status = asyncStatus.SUCCEEDED;
      state.get_service_by_id_data = payload;
    });

    builder.addCase(get_service_by_id.rejected, (state, action) => {
      state.get_service_by_id_status = asyncStatus.ERROR;
      state.get_service_by_id_error = action.error;
      error_toast_message(action.error.message);
    });

    //  Home Main Services =========>>>>>>>>>>>
    builder.addCase(get_services.pending, (state, action) => {
      state.get_all_services_status = asyncStatus.LOADING;
    });
    builder.addCase(get_services.fulfilled, (state, { payload }) => {
      state.get_all_services_status = asyncStatus.SUCCEEDED;
      state.get_all_services_data = payload.data;
      state.get_all_services_error = null;
    });

    builder.addCase(get_services.rejected, (state, action) => {
      state.get_all_services_status = asyncStatus.ERROR;
      state.get_all_services_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  Services Job Post =========>>>>>>>>>>>
    builder.addCase(jobPostAsync.pending, (state, action) => {
      state.post_job_service_status = asyncStatus.LOADING;
    });
    builder.addCase(jobPostAsync.fulfilled, (state, { payload }) => {
      state.post_job_service_status = asyncStatus.SUCCEEDED;
      state.post_job_service_data = payload.data;
      state.post_job_service_error = null;
      success_toast_message("Job Posted Successfully");
    });

    builder.addCase(jobPostAsync.rejected, (state, action) => {
      state.post_job_service_status = asyncStatus.ERROR;
      state.post_job_service_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  Get Job =========>>>>>>>>>>>
    builder.addCase(getJobsAsync.pending, (state, action) => {
      state.get_jobs_status = asyncStatus.LOADING;
    });
    builder.addCase(getJobsAsync.fulfilled, (state, { payload }) => {
      state.get_jobs_status = asyncStatus.SUCCEEDED;
      state.get_jobs_data = payload.orders;
      state.get_jobs_error = null;
    });

    builder.addCase(getJobsAsync.rejected, (state, action) => {
      state.get_jobs_status = asyncStatus.ERROR;
      state.get_jobs_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  Accept Job =========>>>>>>>>>>>
    builder.addCase(acceptJobAsync.pending, (state, action) => {
      state.accept_job_status = asyncStatus.LOADING;
    });
    builder.addCase(acceptJobAsync.fulfilled, (state, { payload }) => {
      state.accept_job_status = asyncStatus.SUCCEEDED;
      state.accept_job_data = payload.order;
      if (Array.isArray(state.get_jobs_data)) {
        state.get_jobs_data = state.get_jobs_data?.filter(
          (item) => item?.id !== payload.order.id
        );
      }
    });

    builder.addCase(acceptJobAsync.rejected, (state, action) => {
      state.accept_job_status = asyncStatus.ERROR;
      state.accept_job_error = action.error;
      console.log("action.error=====>>>>>", action);
      error_toast_message(action.error.message);
    });

    //  get Job by vendor =========>>>>>>>>>>>
    builder.addCase(JobGetByVendorAsync.pending, (state, action) => {
      state.get_job_by_vendor_status = asyncStatus.LOADING;
    });
    builder.addCase(JobGetByVendorAsync.fulfilled, (state, { payload }) => {
      state.get_job_by_vendor_status = asyncStatus.SUCCEEDED;
      state.get_job_by_vendor_data = payload.orders;
      state.get_job_by_vendor_error = null;
    });

    builder.addCase(JobGetByVendorAsync.rejected, (state, action) => {
      state.get_job_by_vendor_status = asyncStatus.ERROR;
      state.get_job_by_vendor_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    // =========>>>>>>> Post Vendor Reviews
    builder.addCase(
      getPaymentRequestDataAsync.pending,
      (state, { payload }) => {
        state.get_payment_request_status = asyncStatus.LOADING;
      }
    );
    builder.addCase(
      getPaymentRequestDataAsync.fulfilled,
      (state, { payload }) => {
        state.get_payment_request_status = asyncStatus.SUCCEEDED;
        state.get_payment_request_data = payload.data;
      }
    );
    builder.addCase(getPaymentRequestDataAsync.rejected, (state, actions) => {
      state.get_payment_request_status = asyncStatus.ERROR;
      error_toast_message(actions.error.message);
    });

    //  get ALL Job =========>>>>>>>>>>>
    builder.addCase(GetAllJobsAsync.pending, (state, action) => {
      state.get_all_jobs_status = asyncStatus.LOADING;
    });
    builder.addCase(GetAllJobsAsync.fulfilled, (state, { payload }) => {
      state.get_all_jobs_status = asyncStatus.SUCCEEDED;
      state.get_all_jobs_data = payload.orders;
      state.get_all_jobs_error = null;
    });

    builder.addCase(GetAllJobsAsync.rejected, (state, action) => {
      state.get_all_jobs_status = asyncStatus.ERROR;
      state.get_all_jobs_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });
    //  job_status_update =========>>>>>>>>>>>
    builder.addCase(updateJobStatusAsync.pending, (state, action) => {
      state.job_status_update_status = asyncStatus.LOADING;
    });
    builder.addCase(updateJobStatusAsync.fulfilled, (state, { payload }) => {
      state.job_status_update_status = asyncStatus.SUCCEEDED;
      state.job_status_update_data = payload.order;
      if (Array.isArray(state.get_all_jobs_data)) {
        state.get_all_jobs_data = state.get_all_jobs_data.filter(
          (item) => item?.id !== payload?.order?.id
        );
      }
      success_toast_message(payload.message);
    });

    builder.addCase(updateJobStatusAsync.rejected, (state, action) => {
      state.job_status_update_status = asyncStatus.ERROR;
      state.job_status_update_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });
    //  searchServiceAsync =========>>>>>>>>>>>
    builder.addCase(searchServiceAsync.pending, (state, action) => {
      state.search_service_status = asyncStatus.LOADING;
    });
    builder.addCase(searchServiceAsync.fulfilled, (state, { payload }) => {
      state.search_service_status = asyncStatus.SUCCEEDED;
      state.search_service_data = payload.services;
      state.search_service_error = null;
      success_toast_message(payload.message);
    });

    builder.addCase(searchServiceAsync.rejected, (state, action) => {
      state.search_service_status = asyncStatus.ERROR;
      state.search_service_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  PostReviewAsync =========>>>>>>>>>>>
    builder.addCase(PostReviewAsync.pending, (state, action) => {
      state.post_review_status = asyncStatus.LOADING;
    });
    builder.addCase(PostReviewAsync.fulfilled, (state, { payload }) => {
      state.post_review_status = asyncStatus.SUCCEEDED;
      state.post_review_data = payload.services;
      state.post_review_error = null;
      success_toast_message(payload.message);
    });

    builder.addCase(PostReviewAsync.rejected, (state, action) => {
      state.post_review_status = asyncStatus.ERROR;
      state.post_review_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  GET VENDOR DATA =========>>>>>>>>>>>
    builder.addCase(getVendorDataAsync.pending, (state, action) => {
      state.get_vendor_status = asyncStatus.LOADING;
    });
    builder.addCase(getVendorDataAsync.fulfilled, (state, { payload }) => {
      state.get_vendor_status = asyncStatus.SUCCEEDED;
      state.get_vendor_data = payload;
      success_toast_message(payload.message);
    });

    builder.addCase(getVendorDataAsync.rejected, (state, action) => {
      state.get_vendor_status = asyncStatus.ERROR;
      state.get_vendor_error = action.error;
      error_toast_message(action.error.message);
    });

    //  Get Job By Id =========>>>>>>>>>>>
    builder.addCase(GetJobByIdAsync.pending, (state, action) => {
      state.get_job_by_id_status = asyncStatus.LOADING;
    });

    builder.addCase(GetJobByIdAsync.fulfilled, (state, { payload }) => {
      state.get_job_by_id_status = asyncStatus.SUCCEEDED;
      state.get_job_by_id_data = payload;
    });

    builder.addCase(GetJobByIdAsync.rejected, (state, action) => {
      state.get_job_by_id_status = asyncStatus.ERROR;
      state.get_job_by_id_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const {
  setUserRole,
  setIdleStatus,
  setpageValue,
  setJobsImages,
  setJobPostStatus,
  setJobAcceptStatus,
} = services_manage_slice.actions;

export default services_manage_slice.reducer;
