import React, { useEffect, useState } from "react";
import css from "./VerifyEmail.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../../../../assets/logo.png";
import { themeOrange } from "../../../../utils/colorTheme";
import Input from "../../../../component/common/Input";
import { common_caption_strings } from "../../../../utils/language_controls/constant_strings";
import ButtonComp from "../../../../component/common/ButtonComp";
import { asyncStatus } from "../../../../utils/asyncStatus";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const {
    user_login_status,
    forgot_password_status,
    forgot_password_data,
    forgot_password_error,
  } = useSelector((state) => state.userAuth);

  const isLoading = forgot_password_status === asyncStatus.LOADING;

  const validateForm = () => {
    let formErrors = {};

    // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.email || !emailRegex.test(data.email)) {
      formErrors.email = "Valid email is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Returns true if no errors
  };

  const _handleInputChange = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });

    // Perform validation for the specific field
    let updatedErrors = { ...errors };

    switch (id) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        updatedErrors.email = emailRegex.test(value)
          ? ""
          : "Valid email is required";
        break;

      default:
        break;
    }

    setErrors(updatedErrors);
  };

  const _handleSubmit = () => {
    // if (validateForm()) {
    //   //   dispatch(forgot-password(data));
    //   localStorage.setItem("email", data.email);
    // }
    navigate("/vendor-check-phone");
  };
  useEffect(() => {
    // if (forgot-password_status === asyncStatus.SUCCEEDED) {
    //   navigate("/check-email");
    //   dispatch(setIdleStatusForgot());
    // }
  }, [forgot_password_status]);
  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  spacing={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <Typography variant="caption" className={css.first_txt}>
                        Verify Your{" "}
                        <Typography
                          variant="caption"
                          className={`grediant_txt ${css.lst_txt}`}
                        >
                          Email
                        </Typography>
                      </Typography>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <Typography className={css.description}>
                        To complete your profile and start taking business
                        account with Done, You’ll need to verify your email
                        address.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Grid mt={4} mb={2} container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Input
                          id={"email"}
                          onChange={_handleInputChange}
                          style={{
                            borderRadius: "9px",
                            boxShadow:
                              "0px 10.534px 31.601px 0px rgba(178, 178, 178, 0.20)",
                            border: "1.053px solid #E4E4E4",
                          }}
                          placeholder={common_caption_strings.example_email}
                          label="Enter Your email"
                          isError={errors.email}
                        />
                        {errors.email && (
                          <Typography
                            sx={{ color: "#d32f2f", fontSize: "12px" }}
                          >
                            {errors?.email || ""}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <Stack>
                  <ButtonComp
                    onClick={_handleSubmit}
                    label={
                      isLoading ? (
                        <CircularProgress size={22} sx={{ color: "white" }} />
                      ) : (
                        "Send Code"
                      )
                    }
                    style={{
                      borderRadius: "10px",
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                    }}
                  />
                </Stack>
                {/* <Stack mt={1} direction={"row"} justifyContent={"center"}>
                  <Typography color={"#9F9F9F"}>
                    {common_caption_strings.dont_have_account}
                  </Typography>
                  <Typography
                    onClick={() => navigate("/Signup")}
                    sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                  >
                    {common_caption_strings.Create_one}
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack alignItems={"center"} className="bgImageSignup" width={1}>
            <Stack
              mt={10}
              width={"70%"}
              className="transparent-bg"
              p={4}
              gap={5}
            >
              <Stack>
                <img width={"80px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  Welcome Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
              {/* <Stack mt={20}>
            <Typography className="mainPara" color={"white"}>
              Already have an Account?
            </Typography>
            <Typography className="mainPara" color={themeOrange}>
              Submit
            </Typography>
          </Stack> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default VerifyEmail;
