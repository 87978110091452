import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const get_service_by_id = createAsyncThunk(
  type_constant.GET_SERVICE_BY_ID,
  async (post_data) => {
    try {
      const response = await apiHandle.get(`/get-service-by-id/${post_data}`);
      const res_data = await response.data;
      console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_services = createAsyncThunk(
  type_constant.GET_SERVICES_HOME,
  async () => {
    try {
      const response = await apiHandle.get(`/get-homepage`);
      const res_data = await response.data;
      //   console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const jobPostAsync = createAsyncThunk(
  type_constant.JOB_POST,
  async (job_data) => {
    try {
      const response = await apiHandle.post(`/job-post`, job_data);
      const res_data = await response.data;
      //   console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error from job post", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getJobsAsync = createAsyncThunk(
  type_constant.GET_JOBS,
  async () => {
    try {
      const response = await apiHandle.get(`/jobs-get`);
      const res_data = await response.data;
      //   console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const acceptJobAsync = createAsyncThunk(
  type_constant.ACCEPT_JOB,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/jobs-accept`, post_data);
      const res_data = await response.data;
      //   console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const searchServiceAsync = createAsyncThunk(
  type_constant.SEARCH_SERVICE,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/search`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const updateJobStatusAsync = createAsyncThunk(
  type_constant.UPDATE_STATUS,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/status-update/${post_data?.id}`, {
        status: post_data?.status,
      });
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const JobGetByVendorAsync = createAsyncThunk(
  type_constant.GET_JOBS_BY_VENDOR,
  async () => {
    try {
      const response = await apiHandle.get(`/jobs-get/vendor`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const GetJobByIdAsync = createAsyncThunk(
  type_constant.GET_SINGLE_JOBS_BY_VENDOR,
  async (id) => {
    try {
      const response = await apiHandle.get(`/job-get/${id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getPaymentRequestDataAsync = createAsyncThunk(
  type_constant.GET_PAYMENT_REQUEST_DATA,
  async () => {
    try {
      const response = await apiHandle.post(`/payment-requests`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const GetAllJobsAsync = createAsyncThunk(
  type_constant.GET_ALL_JOBS,
  async () => {
    try {
      const response = await apiHandle.get(`/user-jobs`);
      const res_data = await response.data;
      //   console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const PostReviewAsync = createAsyncThunk(
  type_constant.POST_REVIEW,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/post-review`, post_data);
      const res_data = await response.data;
      //   console.log("res_data", res_data);
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getVendorDataAsync = createAsyncThunk(
  type_constant.GET_VENDOR_DATA,
  async (post_data) => {
    try {
      const response = await apiHandle.get(`/vendor-dashboard`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error from get jobs", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
