import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { themeOrange } from "../../../utils/colorTheme";
import { CustomTable } from "../../common/customTable/CustomTable";
import { useSelector } from "react-redux";
import { asyncStatus } from "../../../utils/asyncStatus";

const CurrentVendorJobsTab = () => {
  const {
    get_job_by_vendor_status,
    get_job_by_vendor_data,
    get_job_by_vendor_error,
  } = useSelector((state) => state.serviceManage);
  const jobDoneLoader = get_job_by_vendor_status === asyncStatus.LOADING;


  const currentJobs = get_job_by_vendor_data?.filter((job)=> job.status === "Assigned");


  return (
    <div>
      {jobDoneLoader ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "60vh", width: "100%" }}
        >
          <CircularProgress size={30} sx={{ color: themeOrange }} />
        </Stack>
      ) : !currentJobs?.length &&
        currentJobs?.length === 0 ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "60vh", width: "100%" }}
        >
          <Typography>No Jobs Found</Typography>
        </Stack>
      ) : (
        <CustomTable data={currentJobs} />
      )}
    </div>
  );
};

export default CurrentVendorJobsTab;
