import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { themeOrange } from "../../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidCalendar, BiSolidLock } from "react-icons/bi";
import { inputDateFormate } from "../../../utils/CustomFormator";
import { MdLocationOn, MdWatchLater } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import Input from "../../common/Input";
import { asyncStatus } from "../../../utils/asyncStatus";
import { useNavigate } from "react-router-dom";
import { PostReviewAsync } from "../../../services/services";
import { imgUrl } from "../../../utils/imageUrl";
import { format } from "date-fns";
import { LuCalendarClock } from "react-icons/lu";
import CustomModal from "../../common/CustomModal/CustomModal";
import secure_check from "../../..//assets/secure_check.png";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { error_toast_message } from "../../../utils/toast_message";
import CustomButton from "../../common/Button/Button";
import { loadStripe } from "@stripe/stripe-js";
import { RxCross2 } from "react-icons/rx";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const CompletedJobsTabWithoutPayment = ({ jobLoader }) => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobReview, setjobReview] = useState({});
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { get_all_jobs_data, post_review_status } = useSelector(
    (state) => state.serviceManage
  );

  // Sample data - replace with dynamic data
  const items = [
    { name: "Web Development Service", price: 450.0 },
    { name: "UX Consulting", price: 250.0 },
    { name: "Domain Registration", price: 15.0 },
  ];

  const hasMembership = true;
  const memberDiscount = 25.0;
  const taxRate = 0.13;

  // Calculations
  const subtotal = items.reduce((sum, item) => sum + item.price, 0);
  const discount = hasMembership ? memberDiscount : 0;
  const taxableAmount = subtotal - discount;
  const tax = taxableAmount * taxRate;
  const total = taxableAmount + tax;

  const postReviewLoader = post_review_status === asyncStatus.LOADING;
  //console.log(get_all_jobs_data);

  const completedJobs = get_all_jobs_data?.filter((job) => {
    console.log(`"${job?.status}"`, job?.status?.length);  // Debugging
    return job?.status?.trim().toLowerCase() === "aprroval-waiting";  
  });

console.log(completedJobs);  // This will print for each job in the array
  

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  const handleViewDetails = (jobId) => {
    setSelectedJobId((prevId) => (prevId === jobId ? null : jobId));
  };

  const ratingChanged = (newRating) => {
    setjobReview({ ...jobReview, rating: newRating });
  };

  const handlePostReview = (elm) => {
    let obj = {
      msg: jobReview.msg,
      rating: jobReview.rating,
      oid: elm,
    };
    dispatch(PostReviewAsync(obj));

    setjobReview({
      msg: "",
      rating: "",
    });
  };

  const _handlePaymentSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // CardElement se card details extract karna
    const cardElement = elements.getElement(CardElement);

    // Stripe ka token generate karna
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error:", error);
      error_toast_message(error?.message);
    } else {
      // const jobPostObj = {
      //   [service_booking_keys.LOCATION]: location,
      //   [service_booking_keys.SERVICE]: id,
      //   [service_booking_keys.DESCRIPTION]: desc,
      //   [service_booking_keys.PRICE]: price,
      //   [service_booking_keys.IMAGES]: images,
      //   [service_booking_keys.DATE]: date,
      //   [service_booking_keys.TIME]: time,
      //   [service_booking_keys.STRIPETOKEN]: token.id,
      // };

      console.log("data", {
        stripTokern: token.id,
      });
      // formData.append(service_booking_keys.TIME, time);
      // formData.append(service_booking_keys.STRIPETOKEN, token.id);
    }
  };

  //   if (jobLoader) {
  //     return (
  //       <Stack
  //         alignItems="center"
  //         justifyContent="center"
  //         sx={{ height: "60vh" }}
  //       >
  //         <CircularProgress size={30} sx={{ color: themeOrange }} />
  //       </Stack>
  //     );
  //   }

  if (!completedJobs?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "60vh" }}
      >
        <Typography>No Completed Jobs Found</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "15px",
        height: "200px",
      }}
      gap={2}
    >
      {completedJobs
        ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        ?.map((e, i) => {
          const isSelected = selectedJobId === e?.id; // Assume `e.id` is the unique identifier for the job
          const isStatusCancelled = e?.status === "Cancelled";
          return (
            <Stack
              key={e?.id}
              sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "15px",
              }}
            >
              <Stack
                className="globleGradientBlue"
                sx={{
                  borderRadius: "15px",
                  py: 1.5,
                  px: 2,
                  boxShadow: "none",
                }}
              >
                <Typography
                  className="secondSubHeading"
                  sx={{ color: "white" }}
                >
                  {e?.service?.name || ""}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ px: 2, py: 2 }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  gap={2}
                  sx={{ width: "100%" }}
                >
                  <Stack
                    pb={1.5}
                    gap={1}
                    sx={{
                      borderRadius: "10px",
                      width: "150px",
                      backgroundColor: "#F5F5F5",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                  >
                    <Stack sx={{ height: "70px", width: "100%" }}>
                      <img
                        src={`${imgUrl}${e?.service?.mobile_image}`}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Stack>
                    <Typography
                      className="mainPara"
                      sx={{
                        color: "#333333",
                        mx: 1,
                        textAlign: "center",
                      }}
                    >
                      {e?.service?.name || ""}
                    </Typography>
                  </Stack>

                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                    flexWrap={{
                      xl: "nowrap",
                      lg: "nowrap",
                      md: "wrap",
                      sm: "wrap",
                      xs: "wrap",
                    }}
                  >
                    <Stack gap={0.5}>
                      {/* <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                      >
                        <BiSolidCalendar
                          style={{
                            fontSize: "24px",
                            color: "#F15A24",
                            flexShrink: 0,
                          }}
                        />
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {inputDateFormate(e?.date || "")}
                        </Typography>
                      </Stack> */}

                      <Stack sx={{ height: "100%" }}>
                        {Array.isArray(e?.date) &&
                          e?.date?.map(({ selected_date, shifts }) => {
                            return (
                              <Stack
                                mt={0.5}
                                flexDirection={"row"}
                                alignItems={"center"}
                                gap={2}
                              >
                                <Stack>
                                  {/* <FaCalendarDays color={themeOrange} size={26} /> */}
                                  <LuCalendarClock
                                    style={{
                                      color: themeOrange,
                                      fontSize: "26px",
                                      flexShrink: "0",
                                    }}
                                  />
                                </Stack>
                                <Typography
                                  className="subpara"
                                  sx={{ color: "#333333" }}
                                >
                                  {format(selected_date, "EEE, MMM d, yyyy")}
                                </Typography>
                                <Typography className="service_shifts_txt">
                                  (
                                  {shifts
                                    ?.map((shift) => shift?.slice(0, 3))
                                    .join(", ")}
                                  )
                                </Typography>
                              </Stack>
                            );
                          })}
                      </Stack>

                      {e?.time && (
                        <Stack
                          direction={"row"}
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          gap={1}
                        >
                          <MdWatchLater
                            style={{
                              fontSize: "24px",
                              color: "#F15A24",
                              flexShrink: 0,
                            }}
                          />
                          <Typography
                            className="subpara"
                            sx={{ color: "#333333" }}
                          >
                            {e?.time?.length > 30
                              ? `${e?.time?.substring(0, 30)}...`
                              : e?.time}
                          </Typography>
                        </Stack>
                      )}

                      {e?.location && (
                        <Stack
                          direction={"row"}
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          gap={1}
                        >
                          <MdLocationOn
                            style={{
                              fontSize: "24px",
                              color: "#F15A24",
                              flexShrink: 0,
                            }}
                          />
                          <Typography
                            className="subpara"
                            sx={{ color: "#333333" }}
                          >
                            {e?.location}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>

                    <Stack
                      justifyContent={"space-between"}
                      alignItems={"flex-end"}
                      sx={{ height: "100%" }}
                      gap={5}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          backgroundColor: isStatusCancelled ? "red" : "green",
                          padding: "5px 10px",
                          borderRadius: "8px",
                          fontSize: "12px",
                        }}
                      >
                        {e.status}
                      </Typography>

                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "#F15A24",
                          color: "#F15A24",
                          borderRadius: "15px",
                          px: 3,
                          py: 1,
                          backgroundColor: "white",
                          ":hover": {
                            backgroundColor: "white",
                            borderColor: "#F15A24",
                          },
                          textTransform: "none",
                          mt: 1,
                        }}
                        onClick={() => handleViewDetails(e?.id)}
                      >
                        {isSelected ? "Hide Detail" : "View Detail"}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              {isSelected && (
                <Stack
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    px: 1,
                  }}
                >
                  {/* Job Description */}
                  <Stack gap={1} px={2} py={2}>
                    <Typography
                      className="mainHeading"
                      sx={{ color: "#333333", fontSize: "20px" }}
                    >
                      Job Description
                    </Typography>
                    <Stack
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid #F1F1F1",
                      }}
                      px={2}
                      py={2}
                    >
                      <Typography
                        className="mainPara"
                        sx={{ color: "#868181" }}
                      >
                        {e?.description || ""}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* Timing Constraints >>>>>> */}
                  {e?.time && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333", fontSize: "20px" }}
                      >
                        Timing Constraints
                      </Typography>
                      <Stack
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #F1F1F1",
                        }}
                        px={2}
                        py={2}
                      >
                        <Typography
                          className="mainPara"
                          sx={{
                            color: "#868181",
                            textAlign: "start",
                          }}
                        >
                          {e?.time || ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                  {/* Vendor Details >>>>>>>> */}
                  {e?.vendor?.first_name && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333", fontSize: "20px" }}
                      >
                        Vendor Detail
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #F1F1F1",
                        }}
                        px={2}
                        py={2}
                      >
                        <Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Name :
                            </Typography>
                            <Typography
                              className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${e?.vendor?.first_name || ""} ${
                                e?.vendor?.last_name || ""
                              }`}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Email :
                            </Typography>
                            <Typography
                              // className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${e?.vendor?.email || ""} `}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Availability Time :
                            </Typography>
                            <Typography
                              // className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${
                                format(
                                  e?.date_time_lock?.date,
                                  "MMM dd, yyyy"
                                ) || ""
                              } ${e?.date_time_lock?.shift || ""}`}
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* <Button
                  variant="contained"
                  startIcon={
                    <HiMiniChatBubbleLeftRight
                      size={17}
                      sx={{ color: "white" }}
                    />
                  }
                  sx={{
                    borderColor: "#F15A24",
                    borderRadius: "15px",
                    px: 3,
                    py: 1,
                    color: "white",
                    backgroundColor: "#F15A24",
                    ":hover": {
                      backgroundColor: "#F15A24",
                      borderColor: "#F15A24",
                    },
                    mx: 2,
                    my: 2,
                  }}
                  onClick={() =>
                    _handleVanderChat(
                      e?.vendor?.id || "",
                      e?.vendor?.first_name || "",
                      e?.vendor?.last_name || "",
                      e?.vendor?.avatar || ""
                    )
                  }
                >
                  Chat Now
                </Button> */}
                      </Stack>
                    </Stack>
                  )}

                  {/* Multiple Images */}

                  {e?.images?.length > 0 && (
                    <Stack px={2} py={2} sx={{ width: "100%" }}>
                      {e?.images?.map(({ url, caption }) => {
                        return (
                          <Stack
                            key={url}
                            sx={{
                              border: "1px solid #F1F1F1",
                              borderRadius: "20px",
                              padding: "10px",
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Stack
                                  sx={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "rgb(238, 236, 236)",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <img
                                    src={url}
                                    style={{
                                      width: "100%",
                                      objectFit: "contain",
                                      height: "120px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid
                                item
                                xl={10}
                                lg={10}
                                md={10}
                                sm={10}
                                xs={10}
                              >
                                <Stack>
                                  <Typography
                                    className="mainPara"
                                    sx={{
                                      // border: "0.6px solid #F1F1F1",
                                      // padding: "10px",
                                      // borderRadius: "20px",
                                      color: "gray",
                                    }}
                                  >
                                    {caption || ""}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}

                  {/* Reviews */}
                  {/* {!isStatusCancelled && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333" }}
                      >
                        Post Review
                      </Typography>

                      <Stack alignItems={"center"}>
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={40}
                          value={jobReview?.rating}
                          // fontSize={200}
                          activeColor="#ffd700"
                        />
                      </Stack>
                      <Stack p={2}>
                        <Input
                          onChange={(e) =>
                            setjobReview({
                              ...jobReview,
                              msg: e?.target?.value,
                            })
                          }
                          value={jobReview?.msg}
                          label="Write a review"
                        />
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            borderColor: "#F15A24",
                            borderRadius: "15px",
                            px: 3,
                            py: 1,
                            color: "white",
                            background:
                              "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                            ":hover": {
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                              borderColor: "#F15A24",
                            },
                            my: 2,
                          }}
                          onClick={() => handlePostReview(e?.id)}
                        >
                          {postReviewLoader ? (
                            <CircularProgress
                              size={24}
                              sx={{ color: "white" }}
                            />
                          ) : (
                            "Post"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  )} */}

                  <Paper
                    elevation={3}
                    sx={{
                      p: { xs: 2, sm: 4 },
                      maxWidth: 800,
                      margin: "auto",
                      backgroundColor: "#fff",
                      fontFamily: "monospace",
                      mb: 2,
                    }}
                  >
                    {/* Header */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "bold",
                              color: theme.palette.primary.main,
                            }}
                          >
                            Invoice
                          </Typography>
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              Date: {new Date().toLocaleDateString()}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Invoice #: INV-
                              {Math.floor(1000 + Math.random() * 9000)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mb: 3 }} />

                    <Box sx={{ mb: 4 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            Service Amount:
                          </Typography>
                          {hasMembership && (
                            <Typography variant="subtitle1">
                              Done+ Member Discount:
                            </Typography>
                          )}
                          <Typography variant="subtitle1">
                            HST (13%):
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle1">
                            ${(2).toFixed(2)}
                          </Typography>
                          {hasMembership && (
                            <Typography
                              variant="subtitle1"
                              sx={{ color: theme.palette.error.main }}
                            >
                              -${memberDiscount.toFixed(2)}
                            </Typography>
                          )}
                          <Typography variant="subtitle1">
                            ${tax.toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    {/* Items Table */}
                    <TableContainer sx={{ mb: 2 }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                          >
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Additional Items
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              Amount
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {items.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.name}</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                ${item.price.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* Calculations Section */}
                    <Grid
                      container
                      spacing={1}
                      sx={{ maxWidth: 300, ml: "auto", mt: 2 }}
                    >
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Additiona Total Amount:
                        </Typography>

                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Total:
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">
                          ${subtotal.toFixed(2)}
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            color: theme.palette.success.main,
                          }}
                        >
                          ${total.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 3 }} />

                    {/* Footer Note */}
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "italic", textAlign: "center" }}
                    >
                      Thank you for your business. Please make payments payable
                      to [Company Name]
                      <br />
                      Payment due within 30 days of invoice date
                    </Typography>
                  </Paper>
                  <Typography
                      variant="body2"
                      sx={{ fontStyle: "italic", textAlign: "center", marginBottom: '20px' }}
                    >
                      Waiting for the vendor to approve the completion
                    </Typography>
                  {/* <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={5}
                  >
                    <CustomButton
                      onClick={() => setIsOpenModal(!isOpenModal)}
                      style={{
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                      }}
                    >
                      Update Invoice
                    </CustomButton>
                    <CustomButton
                      onClick={() => setIsOpenModal(!isOpenModal)}
                      style={{
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                      }}
                    >
                      Approved Invoice
                    </CustomButton>
                  </Stack> */}

                  {/* <CustomModal
                    isOpen={isOpenModal}
                    setIsOpen={() => setIsOpenModal(!isOpenModal)}
                  >
                    <Stack
                      sx={{
                        overflow: "auto",
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                      alignItems={"center"}
                      p={1}
                    >
                      <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
                        <IconButton
                          onClick={() => {
                            setIsOpenModal(!isOpenModal);
                          }}
                        >
                          <RxCross2 size={20} sx={{ color: "black" }} />
                        </IconButton>
                      </Stack>

                      <Stack gap={2} px={1} sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            margin: "0px 20px 20px 0px",
                          }}
                        >
                          Are you sure you want to logout?
                        </Typography>

                        <Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            my={3}
                            gap={1}
                          >
                            <BiSolidLock
                              style={{ fontSize: "24px", color: themeOrange }}
                            />{" "}
                            <Typography
                              sx={{ fontSize: "20px", fontWeight: "400" }}
                            >
                              Credit Card
                            </Typography>
                          </Stack>
                          <CardElement
                            onChange={handleCardChange}
                            className="stripeInput"
                            options={{ hidePostalCode: true }}
                          />
                          {isCardComplete && (
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              my={1}
                              gap={1}
                            >
                              <img
                                src={secure_check}
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  objectFit: "contain",
                                }}
                              />{" "}
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  color: "#666666",
                                }}
                              >
                                Secure Payment Processing
                              </Typography>
                            </Stack>
                          )}
                        </Stack>

                        <Stack
                          gap={2}
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          {" "}
                          <CustomButton
                            onClick={() => console.log("Pay")}
                            style={{
                              background:
                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                            }}
                          >
                            Pay
                          </CustomButton>
                        </Stack>
                      </Stack>
                    </Stack>
                  </CustomModal> */}
                  <CustomModal
                    isOpen={isOpenModal}
                    setIsOpen={() => setIsOpenModal(!isOpenModal)}
                  >
                    <Stack
                      sx={{
                        overflow: "auto",
                        backgroundColor: "white",
                        borderRadius: { xs: "8px", sm: "16px" },
                        boxShadow: 24,
                        width: { xs: "95vw", sm: "500px", md: "600px" },
                        maxWidth: "100%",
                        maxHeight: "90vh",
                        position: "relative",
                        p: { xs: 2, sm: 3, md: 4 },
                      }}
                    >
                      {/* Close Button */}
                      <IconButton
                        onClick={() => setIsOpenModal(false)}
                        sx={{
                          position: "absolute",
                          right: { xs: 8, sm: 16 },
                          top: { xs: 8, sm: 16 },
                          p: { xs: 0.5, sm: 1 },
                          color: "text.secondary",
                          "&:hover": {
                            backgroundColor: "action.hover",
                            transform: "scale(1.1)",
                          },
                          transition: "all 0.2s ease",
                        }}
                      >
                        <RxCross2 size={20} />
                      </IconButton>

                      <Stack gap={{ xs: 2, sm: 3 }} width="100%">
                        {/* Header */}
                        <Typography
                          variant="h5"
                          fontWeight="600"
                          color="text.primary"
                          fontSize={{ xs: "1.5rem", sm: "1.75rem" }}
                        >
                          Confirm Payment
                        </Typography>

                        {/* Main Content */}
                        <Stack gap={{ xs: 2, sm: 3 }} width="100%">
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            fontSize={{ xs: "0.875rem", sm: "1rem" }}
                          >
                            Please verify your payment details
                          </Typography>

                          {/* Payment Section */}
                          <Stack gap={2}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1.5}
                            >
                              <BiSolidLock
                                style={{
                                  fontSize: { xs: "24px", sm: "28px" },
                                  color: themeOrange,
                                  flexShrink: 0,
                                }}
                              />
                              <Typography
                                variant="h6"
                                fontWeight="500"
                                fontSize={{ xs: "1.1rem", sm: "1.25rem" }}
                              >
                                Credit Card Information
                              </Typography>
                            </Stack>

                            <CardElement
                              onChange={handleCardChange}
                              className="stripeInput"
                              options={{ hidePostalCode: true }}
                            />

                            {isCardComplete && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                mt={1}
                              >
                                <img
                                  src={secure_check}
                                  style={{
                                    height: { xs: "20px", sm: "24px" },
                                    width: { xs: "20px", sm: "24px" },
                                    objectFit: "contain",
                                  }}
                                  alt="Secure"
                                />
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  fontSize={{ xs: "0.75rem", sm: "0.85rem" }}
                                >
                                  256-bit SSL secured payment
                                </Typography>
                              </Stack>
                            )}
                          </Stack>

                          {/* Action Buttons */}
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            gap={2}
                            mt={{ xs: 2, sm: 4 }}
                          >
                            <CustomButton
                              variant="contained"
                              onClick={() => console.log("Pay")}
                              fullWidth={{ xs: true, sm: false }}
                              sx={{
                                background:
                                  "linear-gradient(180deg, #F15A24 0%, #C53F10 100%)",
                                color: "white",
                                px: { xs: 2, sm: 4 },
                                py: { xs: 1, sm: 1.5 },
                                borderRadius: "6px",
                                fontSize: { xs: "0.875rem", sm: "1rem" },
                                minWidth: { xs: "100%", sm: "140px" },
                                "&:hover": {
                                  opacity: 0.9,
                                  transform: { xs: "none", sm: "scale(1.02)" },
                                },
                                transition: "all 0.2s ease",
                              }}
                            >
                              Confirm Payment
                            </CustomButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </CustomModal>

                  {/* Multiple Images */}
                  {/* <Stack
      gap={1}
      className="jobs_images_scrollbar_wrapper"
      direction={"row"}
      alignItems={"center"}
      sx={{
        mx: 2,
      }}
    >
      {e.images.map((image, i) => (
        <img
          key={i}
          src={`${imgUrl}${image.image}`}
          style={{
            height: "105px",
            width: "105px",
            borderRadius: "15px",
          }}
        />
      ))}
    </Stack> */}

                  {/* <Button
        variant="contained"
        sx={{
          borderColor: "#F15A24",
          borderRadius: "15px",
          px: 3,
          py: 1,
          color: "white",
          backgroundColor: "#F15A24",
          ":hover": {
            backgroundColor: "#F15A24",
            borderColor: "#F15A24",
          },
          mx: 2,
          my: 2,
        }}
        onClick={() => handleCancel(e.id)}
      >
        CANCEL JOB
      </Button> */}
                </Stack>
              )}
            </Stack>
          );
        })}
    </Stack>
  );
};

const WaitingJobsTab = () => (
  <Elements stripe={stripePromise}>
    <CompletedJobsTabWithoutPayment />
  </Elements>
);

export default WaitingJobsTab;
