import React, { useState } from "react";
import css from "./VendorDashboard.module.css";
import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetJobByIdAsync } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { imgUrl } from "../../utils/imageUrl";
import { LuCalendarClock } from "react-icons/lu";
import { MdOutlineLocationOn, MdOutlineWatchLater } from "react-icons/md";
import { format } from "date-fns";
import CustomButton from "../../component/common/Button/Button";
import { FaPlus } from "react-icons/fa6";
import { BiEditAlt, BiSolidTrashAlt } from "react-icons/bi";
import { TfiReload } from "react-icons/tfi";
import axios from "axios";
import { baseURL } from "../../config/apiHandle/apiHandle";
import "./chating.css";

const JobDoneDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { get_job_by_id_error, get_job_by_id_data, get_job_by_id_status } =
    useSelector((state) => state.serviceManage);
  const [isShownNegotiation, setIsShownNegotiation] = useState(false);
  const [items, setItems] = useState([]); // Holds the list of items with their prices
  const [item, setItem] = useState({ item_name: "", item_price: "" }); // Holds the current item and its price
  const [editingIndex, setEditingIndex] = useState(null); // Index of the item being edited
  const [isUpdating, setIsUpdating] = useState(false);

  // Add or update an item in the list
  const _handleNegotiationPrice = (e) => {
    e.preventDefault();

    if (item.item_name && item.item_price) {
      const newItem = {
        name: item.item_name,
        price: parseFloat(item.item_price),
      };

      // If editing an item, update the item in the list
      if (editingIndex !== null) {
        const updatedItems = [...items];
        updatedItems[editingIndex] = newItem;
        setItems(updatedItems);
        setEditingIndex(null); // Reset editingIndex after updating
      } else {
        // If not editing, just add a new item
        setItems([...items, newItem]);
      }
      console.log('asdasd', items);
      // Clear the input fields after adding or updating
      setItem({ item_name: "", item_price: "" });
    }
  };

  // Delete an item from the list
  const _deleteItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  // Edit an item in the list
  const _editItem = (index) => {
    setEditingIndex(index); // Set the editing index
    setItem({ item_name: items[index].name, item_price: items[index].price });

    if (item.item_name && item.item_price) {
      const updatedItems = [...items];
      updatedItems[index] = {
        name: item.item_name,
        price: parseFloat(item.item_price),
      };
      setItems(updatedItems);
    }
  };

  // Calculate the total price
  const totalAmount = items.reduce((acc, item) => acc + item.price, 0);
  const getAuthToken = () => {
    return localStorage.getItem('auth') || '';
  };
  const authToken = getAuthToken();

  const handleUpdateOrderPrice = async () => {
    try {
      setIsUpdating(true);

      const payload = {
        addons: items.map(item => ({
          name: item.name,
          price: item.price,
          service_id: get_job_by_id_data?.order?.id // Using the service_id from the item
        }))
      };

      const response = await axios.post(
        `${baseURL}/orders/${id}/addons`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}` // Include auth token in header
          }
        }
      );
      setItems([]);
      setItem({ item_name: "", item_price: "" });
      setIsShownNegotiation(false);
      console.log('Order price updated successfully:', response.data);
      alert('Order price updated successfully!');
     
      dispatch(GetJobByIdAsync(id));

    } catch (error) {
      console.error('Error updating order price:', error);
      alert('Failed to update order price. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    dispatch(GetJobByIdAsync(id));
  }, []);
  const loading = get_job_by_id_status === asyncStatus.LOADING;

  console.log(get_job_by_id_data || "");
  let order = get_job_by_id_data?.order;

  return (
    <Container maxWidth="xl">
      <Stack gap={3}>
        <Stack>
          <Typography variant="caption" className={css.total}>
            Job{" "}
            <Typography variant="caption" className={css.main_heading}>
              Details
            </Typography>
          </Typography>
          <hr color={"#D1D1D1"} />
        </Stack>
        {/* *
        
        
        
        */}
        {loading ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <CircularProgress size={30} sx={{ color: themeOrange }} />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={isShownNegotiation && 8} lg={isShownNegotiation && 8} xl={isShownNegotiation && 8} >
              <Stack sx={{ width: "100%", backgroundColor: "white", borderRadius: "15px", }} >
                <Stack className="globleGradientBlue" sx={{ borderRadius: "15px", py: 1.5, px: 2, boxShadow: "none", }}
                >
                  <Typography className="secondSubHeading" sx={{ color: "white" }}
                  >
                    {order?.service?.name || ""}
                  </Typography>
                </Stack>

                {/* {JSON.stringify(order)} */}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ px: 2, py: 2 }}
                >
                  <Stack direction={"row"} alignItems={"flex-start"} gap={2} sx={{ width: "100%" }}>
                    <Stack pb={1.5} gap={1} sx={{ borderRadius: "10px", width: "150px", backgroundColor: "#F5F5F5", flexShrink: "0", overflow: "hidden", }}>
                      <Stack sx={{ height: "70px", width: "100%" }}>
                        <img src={`${imgUrl}${order?.service?.mobile_image}`} style={{ height: "100%", width: "100%", objectFit: "cover", }} />
                      </Stack>
                      <Typography className="mainPara" sx={{ color: "#333333", mx: 1, textAlign: "center", }}>
                        {order?.service?.name || ""}
                      </Typography>
                    </Stack>

                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ width: "100%", height: "auto", }} flexWrap={{ xl: "nowrap", lg: "nowrap", md: "wrap", sm: "wrap", xs: "wrap", }} >
                      <Stack justifyContent={"space-around"} gap={2} sx={{ height: "100%" }} >
                        <Stack sx={{ height: "100%" }}>
                          {Array.isArray(order?.date) &&
                            order?.date?.map(({ selected_date, shifts }) => {
                              return (
                                <Stack mt={0.5} flexDirection={"row"} alignItems={"center"} gap={2} >
                                  <Stack>
                                    {/* <FaCalendarDays color={themeOrange} size={26} /> */}
                                    <LuCalendarClock style={{ color: themeOrange, fontSize: "20px", flexShrink: "0", }} />
                                  </Stack>
                                  <Typography
                                    className="subpara"
                                    sx={{
                                      color: "#333333", fontSize: "15px !important",
                                    }}
                                  >
                                    {format(selected_date, "EEE, MMM d, yyyy")}
                                  </Typography>
                                  <Typography className="service_shifts_txt">
                                    (
                                    {shifts
                                      ?.map((shift) => shift?.slice(0, 3))
                                      .join(", ")}
                                    )
                                  </Typography>
                                </Stack>
                              );
                            })}
                        </Stack>
                        {order?.time && (
                          <Stack
                            direction={"row"}
                            alignItems={"flex-start"}
                            justifyContent={"flex-start"}
                            gap={2}
                          >
                            <MdOutlineWatchLater
                              style={{
                                fontSize: "20px",
                                color: "#F15A24",
                                flexShrink: 0,
                              }}
                            />
                            <Typography
                              className="subpara"
                              sx={{
                                color: "#333333",
                                fontSize: "15px !important",
                              }}
                            >
                              {order?.time?.length > 30
                                ? `${order?.time?.substring(0, 30)}...`
                                : order?.time}
                            </Typography>
                          </Stack>
                        )}
                        <Stack
                          direction={"row"}
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          gap={2}
                        >
                          <MdOutlineLocationOn
                            style={{
                              fontSize: "20px",
                              color: "#F15A24",
                              flexShrink: 0,
                            }}
                          />
                          <Typography
                            className="subpara"
                            sx={{
                              color: "#333333",
                              fontSize: "15px !important",
                            }}
                          >
                            {order?.location || ""}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      sx={{ height: "100%" }}
                      gap={1}
                    >
                      <Stack gap={1}>
                        {/* <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          gap={1}
                        >
                          <Typography
                            className="subpara"
                            sx={{
                              color: "#333333",
                              fontSize: "15px !important",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Service Amount :
                          </Typography>
                          <Typography className="service_shifts_txt">
                            ${order?.total_amount?.toFixed(2)}
                          </Typography>
                        </Stack> */}






<Stack
    direction={"row"}
    alignItems={"center"}
    justifyContent={"space-between"}
    gap={1}
  >
    <Typography
      className="subpara"
      sx={{
        color: "#333333",
        fontSize: "15px !important",
        whiteSpace: "nowrap",
      }}
    >
      Service Amount :
    </Typography>
    <Typography className="service_shifts_txt">
      ${order?.total_amount?.toFixed(2)}
    </Typography>
  </Stack>

  {/* Parse Addons JSON and List */}
  {order?.add_ons && (
    <Stack gap={1}>
      {JSON.parse(order.add_ons).map((addon, index) => (
        <Stack
          key={index}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Typography
            className="subpara"
            sx={{
              color: "#333333",
              fontSize: "15px !important",
              whiteSpace: "nowrap",
            }}
          >
            {addon.name}:
          </Typography>
          <Typography className="service_shifts_txt">
            ${addon.price.toFixed(2)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )}

  {/* Total Price Including Addons */}
  <Stack
    direction={"row"}
    alignItems={"center"}
    justifyContent={"space-between"}
    gap={1}
  >
    <Typography
      className="subpara"
      sx={{
        color: "#333333",
        fontSize: "15px !important",
        whiteSpace: "nowrap",
      }}
    >
      Total Amount :
    </Typography>
    <Typography className="service_shifts_txt">
      ${(
        order?.total_amount +
        (order?.add_ons
          ? JSON.parse(order.add_ons).reduce((sum, addon) => sum + addon.price, 0)
          : 0)
      ).toFixed(2)}
    </Typography>
  </Stack>


                        
                        {items.length > 0 && (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={1}
                          >
                            <Typography
                              className="subpara"
                              sx={{
                                color: "#333333",
                                fontSize: "15px !important",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Additional Amount :
                            </Typography>
                            <Typography className="service_shifts_txt">
                              ${totalAmount.toFixed(2)}
                            </Typography>
                          </Stack>
                        )}
                        {items.length > 0 && (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={1}
                          >
                            <Typography
                              className="subpara"
                              sx={{
                                color: "#333333",
                                fontSize: "15px !important",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Total Amount :
                            </Typography>
                            <Typography className="service_shifts_txt">
                              $
                              {(
                                order?.total_amount + (totalAmount || 0)
                              )?.toFixed(2)}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      <Typography
                        variant="caption"
                        // className="service_shifts_txt"
                        sx={{ color: "grey !important" }}
                      >
                        <Typography
                          variant="caption"
                          onClick={() =>
                            setIsShownNegotiation(!isShownNegotiation)
                          }
                          // className="service_shifts_txt"
                          sx={{
                            color: "#F15A24 !important",
                            cursor: "pointer",
                            ":hover": {
                              textDecorationLine: "underline",
                            },
                          }}
                        >
                          Click here
                        </Typography>{" "}
                        to add new items or negotiate prices for your order. The
                        total amount will be updated accordingly and shared with
                        the user.
                      </Typography>

                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    px: 1,
                  }}
                >
                  <Stack gap={1} px={2} py={2}>
                    <Typography
                      className="mainHeading"
                      sx={{ color: "#333333", fontSize: "20px !important" }}
                    >
                      Customer Details
                    </Typography>
                    <Stack
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid #F1F1F1",
                      }}
                      px={2}
                      py={2}
                      gap={1}
                    >
                      {order?.user?.first_name && (
                        <Typography
                          className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          Name : {order?.user?.first_name || ""}{" "}
                          {order?.user?.last_name || ""}
                        </Typography>
                      )}
                      {order?.user?.email && (
                        <Typography
                          className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          Email : {order?.user?.email || ""}
                        </Typography>
                      )}
                      {order?.user?.phone && (
                        <Typography
                          className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          Phone Number : {order?.user?.phone || ""}
                        </Typography>
                      )}
                      {order?.user?.address_line_1 && (
                        <Typography
                          className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          {order?.user?.address_line_1 || ""}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    px: 1,
                  }}
                >
                  {/* Job Description */}
                  {order?.description && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333", fontSize: "20px !important" }}
                      >
                        Job Description
                      </Typography>
                      <Stack
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #F1F1F1",
                        }}
                        px={2}
                        py={2}
                      >
                        <Typography
                          className="mainPara"
                          sx={{ color: "#868181" }}
                        >
                          {order?.description || ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}

                  {/* Timing Constraints */}
                  {order?.time && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333", fontSize: "20px !important" }}
                      >
                        Timing Constraints
                      </Typography>
                      <Stack
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #F1F1F1",
                        }}
                        px={2}
                        py={2}
                      >
                        <Typography
                          className="mainPara"
                          sx={{
                            color: "#868181",
                            textAlign: "start",
                          }}
                        >
                          {order?.time || ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}

                  {order?.vendor?.first_name && (
                    <Stack gap={1} px={2} py={2}>
                      <Typography
                        className="mainHeading"
                        sx={{ color: "#333333", fontSize: "20px" }}
                      >
                        Vendor Detail
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #F1F1F1",
                        }}
                        px={2}
                        py={2}
                      >
                        <Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Name :
                            </Typography>
                            <Typography
                              className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${order?.vendor?.first_name || ""} ${order?.vendor?.last_name || ""
                                }`}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              className="mainPara"
                              sx={{
                                color: "black",
                                fontWeight: "600 !important",
                                fontSize: "16px !important",
                              }}
                            >
                              Email :
                            </Typography>
                            <Typography
                              // className="mainPara"
                              sx={{ color: "#868181" }}
                            >
                              {`${order?.vendor?.email || ""} `}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}

                  {/* Multiple Images */}

                  {/* Multiple Images */}
                  {order?.images?.length > 0 && (
                    <Stack px={2} py={2} sx={{ width: "100%" }}>
                      {order?.images?.map(({ url, caption }) => {
                        return (
                          <Stack
                            key={url}
                            sx={{
                              border: "1px solid #F1F1F1",
                              borderRadius: "20px",
                              padding: "10px",
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Stack
                                  sx={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "rgb(238, 236, 236)",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <img
                                    src={url}
                                    style={{
                                      width: "100%",
                                      objectFit: "contain",
                                      height: "120px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid
                                item
                                xl={10}
                                lg={10}
                                md={10}
                                sm={10}
                                xs={10}
                              >
                                <Stack>
                                  <Typography
                                    className="mainPara"
                                    sx={{
                                      // border: "0.6px solid #F1F1F1",
                                      // padding: "10px",
                                      // borderRadius: "20px",
                                      color: "gray",
                                    }}
                                  >
                                    {caption || ""}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}

                  {items.length > 0 && (
                    <Stack my={2}>
                      <CustomButton onClick={handleUpdateOrderPrice} disabled={isUpdating}>
                        {isUpdating ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Update Order Price'}
                      </CustomButton>
                    </Stack>
                  )}

                </Stack>
              </Stack>
            </Grid>
            {isShownNegotiation && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Stack
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "white",
                    height: "100%",
                    overflow: "auto",
                    gap: 2,
                  }}
                >
                  <Stack
                    className="globleGradientBlue"
                    sx={{
                      borderRadius: "15px",
                      py: 1.5,
                      px: 2,
                      boxShadow: "none",
                    }}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      className="secondSubHeading"
                      sx={{ color: "white" }}
                    >
                      Negotiation
                    </Typography>

                  </Stack>
                  <Stack
                    sx={{
                      flex: 1,
                      gap: 2,
                    }}
                  >
                    <Stack
                      // alignItems={"flex-end"}
                      justifyContent={"flex-start"}
                      sx={{
                        height: "100%",
                        borderRadius: "5px",
                        // boxShadow: 1,
                        // border: "1px solid #e4e4e4",
                        // backgroundColor: "#f9f9f9",
                        overflow: "auto",
                        padding: "5px",
                      }}
                    >
                      <form
                        onSubmit={_handleNegotiationPrice}
                        className="negotiation_form"
                      >
                        <input
                          required
                          type="text"
                          placeholder="Enter item"
                          className="negotiation_input"
                          value={item.item_name}
                          onChange={(e) =>
                            setItem({
                              ...item,
                              item_name: e.target.value,
                            })
                          }
                        />
                        <input
                          required
                          type="number"
                          placeholder="Enter item price"
                          className="negotiation_input"
                          value={item.item_price}
                          onChange={(e) =>
                            setItem({
                              ...item,
                              item_price: e.target.value,
                            })
                          }
                        />
                        <IconButton
                          type="submit"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            border: "1px solid #e5e5e5",
                            height: "40px",
                            fontSize: "15px",
                            padding: "10px",
                            flexShrink: "0",
                          }}
                        >
                          <FaPlus style={{ color: "#f15a24" }} />
                        </IconButton>
                      </form>

                      <div className="items_wrapper">
                        {items?.length > 0 && (
                          <>
                            {" "}
                            <Typography className="items_heading">
                              Items
                            </Typography>
                            <ul className="items_container">
                              {items.map((item, index) => (
                                <li key={index} className="item">
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    flex={1}
                                  >
                                    <span className="item_txt">
                                      {item.name}
                                    </span>
                                    <span className="item_txt">
                                      ${item.price.toFixed(2)}
                                    </span>
                                  </Stack>

                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    gap={2}
                                  >
                                    <IconButton
                                      onClick={() => _editItem(index)}
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        border: "1px solid #e5e5e5",
                                        // height: "20px",
                                        // width: "20px",
                                        padding: "5px",
                                        fontSize: "20px",
                                      }}
                                    >
                                      <BiEditAlt style={{ color: "#f15a24" }} />
                                    </IconButton>

                                    <IconButton
                                      onClick={() => _deleteItem(index)}
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        border: "1px solid #e5e5e5",
                                        // height: "20px",
                                        // width: "20px",
                                        padding: "5px",
                                        fontSize: "20px",
                                      }}
                                    >
                                      <BiSolidTrashAlt
                                        style={{ color: "#f15a24" }}
                                      />
                                    </IconButton>
                                  </Stack>
                                </li>
                              ))}
                            </ul>{" "}
                          </>
                        )}
                      </div>


                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
        )}
      </Stack>
    </Container>
  );
};

export default JobDoneDetails;
