import React, { useEffect, useState } from "react";
import css from "./VendorAccount.module.css";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { themeOrange } from "../../../../utils/colorTheme";
import done_logo from "../../../../assets/logo.png";
import stripe_logo from "../../../../assets/stripe_logo.png";
import { ImPlus } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { vendorStripeStartedAsync } from "../../../../services/authentication";
import { asyncStatus } from "../../../../utils/asyncStatus";
import Input from "../../../../component/common/Input/Input";
import { setVendorStripeStatus } from "../../../../store/slices/user_auth_slice";
import { getVendorStatusAsync } from "../../../../services/SubscriptionService";
import { setGetVendorStausSatus } from "../../../../store/slices/subscription_slice";
import ButtonComp from "../../../../component/common/ButtonComp";
import { IoInformationCircleSharp } from "react-icons/io5";
import CustomModal from "../../../../component/common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import CustomButton from "../../../../component/common/Button/Button";

const VendorAccountTab = () => {
  const dispatch = useDispatch();
  const [isAccountFields, setIsAccountFields] = useState(false);
  const [isOpenRegenerateLinkModal, setIsOpenRegenerateLinkModal] =
    useState(false);
  const { vendor_stripe_started_status, user } = useSelector(
    (state) => state.userAuth
  );

  const { get_vendor_status_status, get_vendor_status_data } = useSelector(
    (state) => state.subscriptionSlice
  );

  const isCheckStripeStatusLoading =
    get_vendor_status_status === asyncStatus.LOADING;

  const stripeDetails =
    user?.stripe_details && JSON.parse(user?.stripe_details);

  const isStartedLoading = vendor_stripe_started_status === asyncStatus.LOADING;

  const _handleGetStarted = () => {
    if (user?.account_link) {
      setIsOpenRegenerateLinkModal(true);
    } else {
      dispatch(vendorStripeStartedAsync());
    }
  };

  const _handleOpenClick = () => {
    dispatch(getVendorStatusAsync()); // Opens in a new tab
  };

  const stripeDetailArr = [
    {
      label: "Type",
      value: stripeDetails?.type || "",
    },
    {
      label: "Business Type",
      value: stripeDetails?.business_type || "",
    },
    {
      label: "Currency",
      value: stripeDetails?.default_currency || "",
    },
    {
      label: "Transfers",
      value: stripeDetails?.capabilities?.transfers || "",
    },
    {
      label: "Account Status",
      value: user?.stripe_account_status || "",
    },
    {
      label: "Stripe  Account id",
      value: user?.stripe_id || "",
    },
  ];

  useEffect(() => {
    if (
      vendor_stripe_started_status === asyncStatus.SUCCEEDED &&
      user?.account_link
    ) {
      setIsOpenRegenerateLinkModal(false);
      window.open(user?.account_link, "_blank");
      dispatch(setVendorStripeStatus());
    } else if (vendor_stripe_started_status === asyncStatus.ERROR) {
      console.log(
        "error while the vendor going to registered the stripe account"
      );
    }
  }, [vendor_stripe_started_status, user?.account_link]);

  useEffect(() => {
    if (get_vendor_status_status === asyncStatus.SUCCEEDED) {
      dispatch(setGetVendorStausSatus());
    }
  }, [get_vendor_status_status]);

  const isBusinessProfileMissing =
    get_vendor_status_data?.account_details?.requirements?.past_due?.some(
      (req) => req.startsWith("business_profile")
    );
  const isIndividualMissing =
    get_vendor_status_data?.account_details?.requirements?.past_due?.some(
      (req) => req.startsWith("individual")
    );

  
  return (
    <div>
      {" "}
      <Stack gap={1} pb={2}>
        <hr color={"#D1D1D1"} />
      </Stack>
      {/* <!--------- GET STARTED SECTION OF THE VENDOR ACCOUNT ---------!> */}
      {user?.account_link ? (
        <Stack>
          <Typography
            mb={4}
            className={css.main_heading}
            sx={{
              fontSize: {
                xl: "35px",
                lg: "35px",
                md: "35px",
                sm: "30px",
                xs: "25px",
              },
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Account Details
          </Typography>

          <Grid container spacing={2}>
            {stripeDetailArr.map(({ label, value }, i) => {
              return (
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Stack key={i}>
                    <Typography variant="caption" className={css.label}>
                      {label}
                    </Typography>
                    <Input
                      style={{
                        padding: "10px 15px",
                        borderRadius: "7px",
                        borderColor: "lightgrey",
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                      disabled={true}
                      value={value || ""}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 2 }}
          my={10}
        >
          <img src={done_logo} alt="done_logo" className={css.done_logo} />
          <ImPlus className={css.plus_icon} />
          <img src={stripe_logo} alt="done_logo" className={css.done_logo} />
        </Stack>
      )}
      <Stack
        alignItems={"flex-start"}
        gap={4}
        my={3}
        sx={{
          width: { xl: "70%", lg: "70%", md: "70%", sm: "80%", xs: "100%" },
          mx: "auto",
        }}
      >
        {!user?.account_link && (
          <Typography className={css.account_txt}>
            To receive payments directly from customers on Done Right Away,
            please connect your Stripe account. This will allow us to securely
            process payments and transfer funds to your account. Click the
            button below to get started with Stripe.
          </Typography>
        )}
      </Stack>
      {user?.account_link && (
        <Stack
          p={2}
          mb={3}
          alignItems={"baseline"}
          justifyContent={"center"}
          sx={{
            border: `2px solid ${themeOrange}`,
            borderRadius: "10px",
            backgroundColor: "#f258201a",
          }}
        >
          {user?.account_link && (
            <Typography
              variant="caption"
              className={css.note}
              sx={{
                textAlign: "start !important",
                color: `${themeOrange} !important`,
              }}
            >
              Account Link:{" "}
              <Typography variant="caption" className={css.account_txt}>
                If the link is not redirecting you to Stripe for completing your
                profile, please{" "}
                <Link
                  href={user?.account_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={css.account_txt}
                  sx={{
                    textAlign: "start !important",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="caption"
                    className={css.account_txt}
                    sx={{
                      textAlign: "start !important",
                      color: `${themeOrange} !important`,
                      ":hover": {
                        textDecorationLine: "underline",
                      },
                    }}
                  >
                    click here
                  </Typography>
                </Link>
              </Typography>
            </Typography>
          )}
        </Stack>
      )}
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        {user?.stripe_id && (
          <ButtonComp
            disabled={isCheckStripeStatusLoading}
            onClick={_handleOpenClick}
            label={
              isCheckStripeStatusLoading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Check Stripe Status"
              )
            }
            style={{
              borderRadius: "10px",
              background:
                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
            }}
          />
        )}
        {!user?.stripe_account_status !== "Approved" && (
          <ButtonComp
            disabled={isStartedLoading}
            onClick={_handleGetStarted}
            label={
              user?.account_link ? (
                "Re Generate Link"
              ) : isStartedLoading && !user?.account_link ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Get Started"
              )
            }
            style={{
              borderRadius: "10px",
              background:
                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
            }}
          />
        )}
      </Stack>
      {/* <Stack pb={3} alignItems={"baseline"}>
        {user?.account_link && (
          <Typography
            variant="caption"
            className={css.note}
            sx={{ textAlign: "start !important" }}
          >
            Note:{" "}
            <Link
              href={user?.account_link}
              target="_blank"
              rel="noopener noreferrer"
              className={css.account_txt}
              sx={{
                textAlign: "start !important",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Typography
                variant="caption"
                className={css.account_txt}
                sx={{ textAlign: "start !important" }}
              ></Typography>
            </Link>
          </Typography>
        )}
      </Stack> */}
      <Stack
        direction="row"
        alignItems={"center"}
        className={css.note_wrapper}
        gap={2}
      >
        <IoInformationCircleSharp
          style={{ color: "orange", fontSize: "20px" }}
        />
        <Typography className={css.note_txt}>Note : </Typography>
        <Typography className={css.note_desc}>
          If you're redirected back means link is expired. Kindly regenerate the
          link.
        </Typography>
      </Stack>
      {/* <Stack py={3}>
        {user?.account_link && (
          <Typography
            variant="caption"
            className={css.note}
            sx={{ textAlign: "start !important" }}
          >
            Account Link :{" "}
            <Typography
              variant="caption"
              className={css.account_txt}
              sx={{ textAlign: "start !important" }}
            >
              {user?.account_link}
            </Typography>
          </Typography>
        )}
      </Stack> */}
      {user?.stripe_account_status === "Rejected" && (
        <Stack my={3} gap={1}>
          <Typography
            sx={{ color: themeOrange, fontSize: "25px", fontWeight: "600" }}
          >
            {get_vendor_status_data?.message &&
              "Your account is not approved due to pending requirements by stripe"}
          </Typography>
          {get_vendor_status_data?.account_details?.requirements?.past_due
            .length > 0 && (
            <Typography sx={{ color: "black" }}>
              Your Stripe profile is incomplete. Please update the{" "}
              {isBusinessProfileMissing ? "business profile" : ""}{" "}
              {isBusinessProfileMissing && isIndividualMissing && "and"}{" "}
              {isIndividualMissing ? "individuals" : ""} Details to complete
              your profile.{" "}
            </Typography>
          )}
        </Stack>
      )}
      {false ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "60vh", width: "100%" }}
        >
          <CircularProgress size={30} sx={{ color: themeOrange }} />
        </Stack>
      ) : false ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "60vh", width: "100%" }}
        >
          {/* <Typography>No Profile Found</Typography> */}
        </Stack>
      ) : (
        <Stack>
          <Grid container>
            {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Stack
                sx={{
                  border: "1px solid #D1D1D1",
                  borderRadius: "10px",
                  mx: "15px",
                }}
                justifyContent={"space-between"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                  <Stack
                    sx={{
                      boxShadow: "0px 0px 7px 0px grey",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <img
                      width={"120px"}
                      src={displayedImage}
                      style={{ objectFit: "contain", borderRadius: "10px" }}
                    />
                    {isDisable && (
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          position: "absolute",
                          top: "-2px",
                          right: "-10px",
                          borderRadius: "100%",
                          height: "36px",
                          width: "36px",
                          zIndex: "10",
                          backgroundColor: "#353535",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsOpenModal(true)}
                      >
                        <BiExport
                          color="#EC9430"
                          style={{ fontSize: "16px" }}
                        />
                      </Stack>
                    )}

                    <CustomModal isOpen={isOpenModal} setIsOpen={_handleModal}>
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          position: "relative",
                          backgroundColor: "#CB5C0A",
                          px: 10,
                          py: 5,
                          borderRadius: "30px",
                        }}
                        gap={3}
                      >
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                          }}
                          onClick={() => {
                            setIsOpenModal(false);
                            setSelectedImage(null);
                          }}
                        >
                          <RxCross2
                            style={{ fontSize: "20px", color: "white" }}
                          />
                        </IconButton>

                        {selectedImage && (
                          <Box
                            sx={{
                              height: "180px",
                              width: "180px",
                              objectFit: "contain",
                            }}
                          >
                            <img
                              width={"100%"}
                              height={"100%"}
                              src={URL.createObjectURL(selectedImage)}
                              style={{ objectFit: "contain" }}
                            />
                          </Box>
                        )}
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            height: "140px",
                            border: "0.5px solid white",
                            borderRadius: "10px",
                            p: 4.5,
                            cursor: "pointer",
                          }}
                          gap={2}
                          onClick={_handleUploadClick}
                        >
                          <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              height: "100%",
                              borderRadius: "10px",
                              p: 1.5,
                              position: "relative",
                              width: "100%",
                            }}
                            gap={2}
                          >
                            <input
                              id="upload-input"
                              type="file"
                              multiple
                              accept="image/*"
                              onChange={_handleFileChange}
                              style={{ display: "none" }}
                            />
                            <MdLibraryAdd size={48} color={themeOrange} />
                            <Stack className="add-photo-title">
                              <img
                                width={"40px"}
                                height={"40px"}
                                src={exportImg}
                                style={{ objectFit: "contain" }}
                              />
                            </Stack>
                          </Stack>

                          <Typography
                            sx={{
                              color: "white",
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            Upload Image
                          </Typography>
                        </Stack>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "white",
                            height: "45px",
                            width: "auto",
                            fontSize: "15px",
                            fontWeight: "600",
                            ":hover": {
                              backgroundColor: "white",
                            },
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            mx: 2,
                            color: themeOrange,
                          }}
                          onClick={_handleUploadImage}
                        >
                          Upload Image
                        </Button>
                      </Stack>
                    </CustomModal>
                  </Stack>


                  <Stack>
                    <Typography fontWeight={600} className="subHeading">
                      {`${get_user_profile_data?.first_name || ""} ${
                        get_user_profile_data?.last_name || ""
                      }`}
                    </Typography>
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={3}
                    >
                      <Typography className="subPara">
                        {vendor_rating || ""}/5
                      </Typography>
                      <ReactStars
                        edit={false}
                        count={5}
                        value={vendor_rating || 0}
                        // onChange={ratingChanged}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Stack p={2}>
                  <Button
                    variant="contained"
                    startIcon={isDisable ? "" : <MdOutlineEdit />}
                    sx={{
                      backgroundColor: "#F15A24",
                      height: "45px",
                      width: "98px",
                      fontSize: "15px",
                      fontWeight: "700",
                      ":hover": {
                        backgroundColor: "#F15A24",
                      },
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setIsDisable(!isDisable)}
                  >
                    {isDisable ? "Cancel" : "Edit"}
                  </Button>
                </Stack>
              </Stack>
            </Grid> */}

            {/* {userProfileData?.map(
              ({
                id,
                label,
                value,
                disable,
                type,
                placeholder,
                defaultVal,
                onchange,
                key,
              }) => {
                const isPassword = label === "Password";
                const isConfirmPassword = label === "Confirm Password";

                return isPassword || isConfirmPassword ? (
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Stack p={2}>
                      <Stack position={"relative"}>
                        <Input
                          id={id}
                          type={type}
                          onChange={onchange}
                          style={{ borderRadius: "10px" }}
                          placeholder={placeholder}
                          label={label}
                          disabled={disable}
                          defaultValue={defaultVal}
                          // value={password}
                        />

                        {showPass[key] ? (
                          <BsFillEyeFill
                            onClick={() =>
                              disable ? "" : _toggleShowPassword(key)
                            }
                            style={{
                              position: "absolute",
                              top: 20,
                              right: 10,
                              cursor: "pointer",
                              color: disable ? "#D1D1D1" : "black",
                            }}
                            size={18}
                          />
                        ) : (
                          <BsFillEyeSlashFill
                            onClick={() =>
                              disable ? "" : _toggleShowPassword(key)
                            }
                            style={{
                              position: "absolute",
                              top: 20,
                              right: 10,
                              cursor: "pointer",
                              color: disable ? "#D1D1D1" : "black",
                            }}
                            size={18}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                ) : (
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Stack p={2}>
                      <Input
                        id={id}
                        type={type}
                        //   value={value}
                        onChange={onchange}
                        //   sx={{ borderRadius: "86px", backgroundColor: 'blue' }}
                        sx={{ backgroundColor: "grey" }}
                        placeholder={placeholder}
                        label={label}
                        disabled={disable}
                        defaultValue={defaultVal}
                      />
                    </Stack>
                  </Grid>
                );
              }
            )} */}

            {/* {isDisable && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F15A24",
                  height: "45px",
                  width: "100%",
                  fontSize: "15px",
                  fontWeight: "700",
                  ":hover": {
                    backgroundColor: "#F15A24",
                  },
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: 2,
                }}
                onClick={handleEdit}
              >
                {!profileUpdateLoader ? (
                  "Update"
                ) : (
                  <CircularProgress size={15} sx={{ color: "white" }} />
                )}
              </Button>
            )} */}
          </Grid>
        </Stack>
      )}
      <CustomModal
        isOpen={isOpenRegenerateLinkModal}
        setIsOpen={() => setIsOpenRegenerateLinkModal(false)}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          onClick={() => setIsOpenRegenerateLinkModal(false)}
        >
          <RxCross2 style={{ fontSize: "20px", color: themeOrange }} />
        </IconButton>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            backgroundColor: "white",
            px: 2,
            py: 5,
            borderRadius: "10px",
          }}
          gap={3}
        >
          <Stack
            sx={{
              width: {
                xl: "30vw",
                lg: "35vw",
                md: "50vw",
                sm: "70vw",
                xs: "80vw",
              },
            }}
          >
            <Typography variant="caption" sx={{ color: "black" }}>
              Regenerating the link will replace your current account setup, and
              all previous data associated with your old link will be
              overwritten. Proceed only if you're sure you want to generate a
              new link.
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <CustomButton
              onClick={() => dispatch(vendorStripeStartedAsync())}
              style={{
                background:
                  "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
              }}
            >
              {isStartedLoading ? (
                <CircularProgress size={15} sx={{ color: "white" }} />
              ) : (
                "Yes"
              )}
            </CustomButton>
            <CustomButton
              onClick={() => setIsOpenRegenerateLinkModal(false)}
              style={{
                background:
                  "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
              }}
            >
              No
            </CustomButton>
          </Stack>
        </Stack>
      </CustomModal>
    </div>
  );
};

export default VendorAccountTab;
