import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  Button,
  Grid,
} from "@mui/material";
// import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
// import { RefressButton } from '../../assets/buttons/RefressButton'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { asyncStatus } from '../../utils/async_status'

import { IoMdShareAlt } from "react-icons/io";
import { setPDFdataState } from "../../../store/slices/layoutControler";
import CustomButton from "../Button/Button";
import { inputDateFormate } from "../../../utils/CustomFormator";
import { themeOrange } from "../../../utils/colorTheme";

const columns = [
  {
    id: "username",
    displayName: "Username",
    minWidth: 20,
    align: "center",
    sortAction: false,
  },

  {
    id: "service",
    displayName: " Service",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "email",
    displayName: "Email",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },

  {
    id: "amount",
    displayName: "amount",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "status",
    displayName: "Status",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "date",
    displayName: "Date",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
  {
    id: "view",
    displayName: "Actions",
    minWidth: 100,
    align: "center",
    sortAction: true,
  },
];

export const CustomTable = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    get_job_by_vendor_status,
    get_job_by_vendor_data,
    get_job_by_vendor_error,
  } = useSelector((state) => state.serviceManage);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (get_job_by_vendor_data?.length) {
      setRows([...data]);
    }
  }, [get_job_by_vendor_data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Box>
        <Stack>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{ maxHeight: 700, borderRadius: "10px 10px 0px 0px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  className="globleGradientBlue"
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        align={column.align}
                        className="globleGradientBlue"
                        style={{
                          minWidth: column.minWidth,
                          color: "white",
                          border: "none",
                        }}
                      >
                        {column.displayName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {
                  // status === asyncStatus.LOADING ? (
                  //   <TableBodyDataSkeleton numberOfColumns={columns.length} imagePreview={false} editAction={false} deleteAction={false} />
                  // ) :
                  rows ? (
                    <TableBody>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          console.log("rowsssssss", row);
                          return (
                            <TableRow hover tabIndex={-1} key={i}>
                              {columns.map((column, ind) => {
                                const value = row[column.id];
                                const isCompleted =
                                  row[column.id] === "Completed";
                                const isAssigned =
                                  row[column.id] === "Assigned";
                                return column.id === "username" ? (
                                  <TableCell key={ind} align={column.align}>
                                    {/* {page < 1
                                      ? i + 1
                                      : page * rowsPerPage + (i + 1)} */}

                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      gap={2}
                                    >
                                      {/* <Stack
                                        sx={{
                                          borderRadius: "100%",
                                          height: "50px",
                                          width: "50px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          src={tble_img_1}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "containf",
                                          }}
                                        />
                                      </Stack> */}

                                      <Typography
                                        className="subpara"
                                        sx={{
                                          color: "#666262c2",
                                          lineHeight: "normal",
                                          fontSize: "16px !important",
                                          fontWeight: "400 !important",
                                        }}
                                      >
                                        {/* johnnc001 */}
                                        {`${row?.user?.first_name || ""} ${
                                          row?.user?.last_name || ""
                                        }`}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                ) : column.id === "name" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      johnnc001
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "service" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {row?.service?.name || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "email" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {row?.user?.email || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "amount" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      $
                                      {(+row?.service
                                        ?.price)?.toLocaleString() || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "status" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      // className="subpara"
                                      sx={{
                                        backgroundColor: isCompleted
                                          ? "lightgreen"
                                          : isAssigned
                                          ? "#80008030"
                                          : "#f300002e",
                                        color: isCompleted
                                          ? "green"
                                          : isAssigned
                                          ? "purple"
                                          : "red",
                                        borderRadius: "10px",
                                        py: "4px",
                                        px: "10px",
                                      }}
                                    >
                                      {row?.status || ""}
                                    </Typography>
                                  </TableCell>
                                ) : column.id === "date" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                        fontSize: "16px !important",
                                        fontWeight: "400 !important",
                                      }}
                                    >
                                      {inputDateFormate(
                                        row?.date_time_lock.date || ""
                                      ) ?? ""}
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  <TableCell key={ind} align={column.align}>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#666262c2",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      <CustomButton
                                        onClick={() =>
                                          navigate(
                                            `/dashboard/job-done/${row?.id}`
                                          )
                                        }
                                        children={"View Details"}
                                        style={{
                                          background:
                                            "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                                        }}
                                      />
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      {/* {!rows.length ? <Show_Message_Center lengthOfColumns={columns.length} message="No Admission Found" co /> : null} */}
                    </TableBody>
                  ) : null
                }
              </Table>
            </TableContainer>

            {
              // status === asyncStatus.LOADING ? (
              //   <PaginationSkeleton />
              // ) :
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            }
          </Paper>
        </Stack>
      </Box>
    </>
  );
};
