import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Checkbox, CircularProgress, Grid, Typography } from "@mui/material";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/common/Input";
import ButtonComp from "../../component/common/ButtonComp";
import { themeBlue, themeOrange } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import authImage from "../../assets/auth_image.png";
import Logo from "../../assets/logo.png";
import "./auth.css";
import { common_caption_strings } from "../../utils/language_controls/constant_strings";
import { login_service_auth } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  setIdleStatus,
  setIdleStatusnemPassword,
} from "../../store/slices/user_auth_slice";
import { Form, Formik } from "formik";
import { getValidationSchema } from "./ValidationsFormikYup/ValidationsSchema";
import { SERVICE_DETAIL_PAGE_CONSTANT } from "../../store/constants";
import Cookies from "js-cookie";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [rememberMe, setRememberMe] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultPassword, setDefaultPassword] = useState("");
  const [defaultUserRole, setDefaultUserRole] = useState("");
  const [showPass, setShowPass] = useState(true);
  const [fromWherePage, setFromWherePage] = useState({ from: "", id: "" });
  const { user_login_status, user } = useSelector((state) => state.userAuth);

  const isLoading = user_login_status === asyncStatus.LOADING;

  const _handleSubmit = (values) => {
    const { ...rest } = values;
    const payload = {
      ...rest,
      user_role: state.user_role || defaultUserRole,
    };

    if (rememberMe) {
      Cookies.set("email", values.email);
      Cookies.set("password", values.password);
      Cookies.set("user_role", state.user_role);
      Cookies.set("rememberMe", true);
    } else {
      // If not checked, clear credentials from localStorage
      Cookies.remove("email");
      Cookies.remove("password");
      Cookies.remove("user_role");
      Cookies.remove("rememberMe");
    }
    console.log("Submitted payload:", payload);
    dispatch(login_service_auth(payload));
  };

  useEffect(() => {
    const serviceDetail = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT.from
    );
    const service_id = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT.service_id
    );
    setFromWherePage({ ...fromWherePage, id: service_id, from: serviceDetail });

    const savedEmail = Cookies.get("email");
    const savedPassword = Cookies.get("password");
    const savedUserRole = Cookies.get("user_role");
    const rememberMe = Cookies.get("rememberMe");

    if (rememberMe === "true") {
      setDefaultEmail(savedEmail);
      setDefaultPassword(savedPassword);
      setDefaultUserRole(savedUserRole);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (user_login_status === asyncStatus.SUCCEEDED) {
      if (user && user?.email_verified_at) {
        if (+user?.user_role === 3) {
          if (fromWherePage.from === SERVICE_DETAIL_PAGE_CONSTANT.from) {
            navigate(`/service-details/${fromWherePage.id}`);
          } else {
            navigate("/");
          }
        } else if (+user?.user_role === 2) {
          navigate("/dashboard/");
        }
      }

      dispatch(setIdleStatus(setIdleStatus));
    }
    dispatch(setIdleStatusnemPassword());
  }, [user_login_status]);

  console.log("savedEmail", defaultEmail);
  console.log("savedPassword", defaultPassword);
  // console.log("savedUserRole", defaultUserRole);
  console.log("rememberMe", rememberMe);

  const initialValues = {
    email: defaultEmail || "",
    password: defaultPassword || "",
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Container maxWidth={"xl"}>
            <Stack mt={2}>
              <Stack
                justifyContent={"flex-start"}
                sx={{
                  width: "100%",
                  padding: 5,
                }}
                textAlign={"left"}
                color={"black"}
              >
                <Stack
                  gap={3}
                  mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
                  mb={2}
                >
                  <Stack spacing={1}>
                    <Stack className="auth_Heading">
                      <span className="auth_Heading">
                        Welcome To{" "}
                        <span style={{ color: themeOrange }}>Done</span>
                      </span>
                    </Stack>
                    <Stack className="auth_sub_Heading">
                      <span>Please login to continue your account.</span>
                    </Stack>
                  </Stack>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={getValidationSchema("login")}
                    onSubmit={_handleSubmit}
                    enableReinitialize
                  >
                    {({ errors, touched, setFieldValue, values }) => {
                      console.log("values.email", values.email);
                      return (
                        <Form>
                          <Stack gap={3} mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Input
                                  id={"email"}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "email",
                                      e.target.value.trim()
                                    )
                                  }
                                  style={{ borderRadius: "6px" }}
                                  placeholder={
                                    common_caption_strings.example_email
                                  }
                                  label="Email"
                                  value={values.email}
                                  isError={touched.email && errors.email}
                                />
                                {touched.email && errors.email && (
                                  <Typography
                                    sx={{ color: "#d32f2f", fontSize: "12px" }}
                                  >
                                    {errors.email}
                                  </Typography>
                                )}
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Stack position={"relative"}>
                                  <Input
                                    id={"password"}
                                    type={showPass ? "password" : "text"}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "password",
                                        e.target.value.trim()
                                      )
                                    }
                                    value={values.password}
                                    style={{ borderRadius: "6px" }}
                                    placeholder={
                                      common_caption_strings.password
                                    }
                                    label="Password"
                                    isError={
                                      touched.password && errors.password
                                    }
                                  />

                                  {showPass ? (
                                    <BsFillEyeFill
                                      onClick={() => setShowPass(false)}
                                      style={{
                                        position: "absolute",
                                        top: 20,
                                        right: 10,
                                        cursor: "pointer",
                                      }}
                                      size={18}
                                    />
                                  ) : (
                                    <BsFillEyeSlashFill
                                      onClick={() => setShowPass(true)}
                                      style={{
                                        position: "absolute",
                                        top: 20,
                                        right: 10,
                                        cursor: "pointer",
                                      }}
                                      size={18}
                                    />
                                  )}
                                </Stack>
                                {touched.password && errors.password && (
                                  <Typography
                                    sx={{ color: "#d32f2f", fontSize: "12px" }}
                                  >
                                    {errors.password}
                                  </Typography>
                                )}
                                <Stack
                                  my={0.5}
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <label
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      cursor: "pointer",
                                    }}
                                    
                                  >
                                    <Checkbox
                                      checked={rememberMe}
                                      onChange={(e) =>
                                        setRememberMe(e.target.checked)
                                      }
                                      sx={{
                                        color: themeOrange,
                                        "&.Mui-checked": {
                                          color: themeOrange,
                                        },
                                        padding:"0px"
                                      }}
                                    />
                                    <Typography
                                      sx={{ cursor: "pointer" }}
                                     
                                      className="mainPara"
                                    >
                                      Remember me
                                    </Typography>
                                  </label>

                                  <Typography
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate("/forgot-password")}
                                    className="mainPara"
                                  >
                                    Forgot password
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>

                            <ButtonComp
                              type="submit"
                              label={
                                isLoading ? (
                                  <CircularProgress
                                    size={22}
                                    sx={{ color: "white" }}
                                  />
                                ) : (
                                  common_caption_strings.Login
                                )
                              }
                              style={{
                                borderRadius: "10px",
                                background:
                                  "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                                boxShadow:
                                  "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                              }}
                            />
                          </Stack>
                        </Form>
                      );
                    }}
                  </Formik>
                </Stack>

                <Stack mt={1} direction={"row"} justifyContent={"center"}>
                  <Typography color={"#9F9F9F"}>
                    {common_caption_strings.dont_have_account}
                  </Typography>
                  <Typography
                    onClick={() => navigate("/signup-user")}
                    sx={{ color: themeOrange, mx: "2px", cursor: "pointer" }}
                  >
                    {common_caption_strings.Create_one}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className="bgImageSignup"
            width={1}
          >
            <Stack
              // mt={10}
              width={"60%"}
              className="transparent-bg"
              p={4}
              gap={5}
            >
              <Stack>
                <img width={"100px"} src={Logo} />
              </Stack>
              <Stack>
                <Typography
                  textAlign={"start"}
                  className="subHeading"
                  color={"white"}
                >
                  {common_caption_strings.Login} For Done Right Away
                </Typography>
                <Typography className="mainPara" color={"white"}>
                  Welcome to our platform, where vendors and users connect
                  effortlessly. Discover a wide range of services, communicate
                  directly with providers, and get personalized solutions to
                  meet your specific needs. Start exploring now!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default Login;
