import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { io } from "socket.io-client";
import UserList from "../../component/ChatApp/userList";
import { useDispatch, useSelector } from "react-redux";
import ChatApp from "../../component/ChatApp/ChatApp";
import chat_start from "../../assets/chat_start.png";
import { getChatUsersListAsync } from "../../services/chatService";
import { useLocation } from "react-router-dom";
import css from "../Dashboard/VendorDashboard.module.css";

const APP_ID = "e5437632a1fa494585e94a09844d69f7";
const CHANNEL_NAME = "TEST-CHANNEL";
const AGORA_TOKEN = "b0875397b4a441aca1926ac4965307ed";

const ViewMessages = () => {
  const location = useLocation();
  const { id, first_name, last_name, avatar } = location.state || {};
  const { user } = useSelector((state) => state.userAuth);
  const { get_all_chats_data } = useSelector((state) => state.chatManage);

  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState("");
  const [callType, setCallType] = useState(null);

  const socket = useMemo(
    () => io("https://chat-soft-2f178ffd0d28.herokuapp.com"),
    []
  );
  const dispatch = useDispatch();

  const createRoomId = (senderId, receiverId) => {
    const ids = [senderId, receiverId].sort();
    return `${ids[0]}-${ids[1]}`;
  };

  const handleSubmit = () => {
    if (!roomId || !message || !user?.id || !(id || selectedUser?.id)) return;
    const currentDate = new Date().toISOString();
    socket.emit("message", {
      message,
      roomId,
      senderId: user?.id,
      receiverId: id || selectedUser?.id,
      createdAt: currentDate,
      companyId: "66c7dcd95dbdcd5ccb67432a",
    });
    setMessage("");
  };

  // const joinRoom = () => {
  //   if (!user?.id || !(id || selectedUser?.id)) return;

  //   const newRoomId = createRoomId(user?.id, id || selectedUser?.id);
  //   setRoomId(newRoomId);
  //   socket.emit("join-room", newRoomId);
  //   socket.emit("fetch-messages", {
  //     roomId: newRoomId,
  //     companyId: "66c7dcd95dbdcd5ccb67432a",
  //   });
  // };

  const joinRoom = () => {
    if (!user?.id || !(id || selectedUser?.id)) return;

    const newRoomId = createRoomId(user?.id, id || selectedUser?.id);
    setRoomId(newRoomId); // Update roomId state
    socket.emit("join-room", newRoomId);
    socket.emit("fetch-messages", {
      roomId: newRoomId,
      companyId: "66c7dcd95dbdcd5ccb67432a",
    });
  };

  console.log("messages", messages);

  useEffect(() => {
    if (id || selectedUser?.id) {
      joinRoom();
    }
  }, [id, selectedUser?.id]);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("connected", socket.id);
  //   });

  //   socket.on("receive-message", (data) => {
  //     if (!data.createdAt) {
  //       data.createdAt = new Date().toISOString();
  //       console.log("receive-message", data);
  //     }

  //     setMessages((prevMessages) => [...prevMessages, data]);
  //   });

  //   // socket.on("previous-messages", (data) => {
  //   //   console.log("previous-message", data);
  //   //   setMessages(data);
  //   // });

  //   socket.on("previous-messages", (data) => {
  //     console.log("previous-message", data);
  //     setMessages(data);

  //     // If no previous messages exist, send the vendor's initial message
  //     if (data?.length === 0 && user?.user_role === 2) {
  //       const initialMessage = {
  //         roomId,
  //         senderId: user?.id,
  //         receiverId: id || selectedUser?.id,
  //         message: `Hey ${first_name || selectedUser?.first_name}, I'm ${
  //           user?.first_name
  //         }, your pro. Let me know if there's any more job details you want to discuss before our appointment!`,
  //         createdAt: new Date().toISOString(),
  //         companyId: "66c7dcd95dbdcd5ccb67432a",
  //       };

  //       socket.emit("message", initialMessage);
  //     }
  //   });
  //   return () => {
  //     socket.off();
  //   };
  // }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected", socket.id);
    });

    socket.on("receive-message", (data) => {
      if (!data.createdAt) {
        data.createdAt = new Date().toISOString();
        console.log("receive-message", data);
      }

      setMessages((prevMessages) => [...prevMessages, data]);
    });

    socket.on("previous-messages", (data) => {
      console.log("previous-message", data);
      setMessages(data);

      // If no previous messages exist and the user role is "vendor"
      if (data?.length === 0 && user?.user_role === 2) {
        const initialMessage = {
          roomId, // Use roomId from state
          senderId: user?.id,
          receiverId: id || selectedUser?.id,
          message: `Hey ${first_name || selectedUser?.first_name}, I'm ${
            user?.first_name
          }, your pro. Let me know if there's any more job details you want to discuss before our appointment!`,
          createdAt: new Date().toISOString(),
          companyId: "66c7dcd95dbdcd5ccb67432a",
        };

        // Send the initial message only after roomId is confirmed
        socket.emit("message", initialMessage);
      }
    });

    return () => {
      socket.off();
    };
  }, [roomId]); // Add roomId as a dependency
  useEffect(() => {
    dispatch(getChatUsersListAsync());
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Stack gap={1}>
        <Typography variant="caption" className={css.total}>
          Mess
          <Typography variant="caption" className={css.main_heading}>
            ages
          </Typography>
        </Typography>
        <Divider />
      </Stack>

      <Grid
        container
        alignItems={"stretch"}
        sx={{
          marginTop: "10px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        {get_all_chats_data?.length > 0 ? (
          <>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={12}
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: id || selectedUser?.id ? "none" : "block",
                  xs: id || selectedUser?.id ? "none" : "block",
                },
                position: "relative",
                height: "80vh",
              }}
            >
              <Stack
                sx={{
                  height: "100%",
                  backgroundColor: "#efefef",
                  position: "absolute",
                  left: "0px",
                  width: "100%",
                }}
              >
                <UserList onSelectUser={setSelectedUser} />
              </Stack>
            </Grid>

            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={id || selectedUser?.id ? 12 : 8}
              xs={id || selectedUser?.id ? 12 : 8}
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: id || selectedUser?.id ? "block" : "none",
                  xs: id || selectedUser?.id ? "block" : "none",
                },
                backgroundColor: "white",
                height: "80vh",
              }}
            >
              <Stack sx={{ height: "100%" }}>
                {id || selectedUser?.id ? (
                  <ChatApp
                    messages={messages}
                    setMessage={setMessage}
                    sendMessage={handleSubmit}
                    userName={`${first_name || selectedUser?.first_name} ${
                      last_name || selectedUser?.last_name
                    }`}
                    backValue={() => setSelectedUser(null)}
                    handleVideCall={() => setCallType("video")}
                    handleAudioCall={() => setCallType("audio")}
                    user_profile={avatar || selectedUser?.avatar}
                  />
                ) : (
                  <Stack
                    sx={{
                      height: "100%",
                      backgroundColor: "white",
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img
                      src={chat_start}
                      style={{
                        height: "25vw",
                        objectFit: "contain",
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
          </>
        ) : (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "100%", height: "60vh" }}
          >
            <Typography
              sx={{
                fontFamily: '"Poppins", sans-serif',
                color: "#9D9D9D",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              No Users found
            </Typography>
          </Stack>
        )}
      </Grid>
    </div>
  );
};

export default ViewMessages;
