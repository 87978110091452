import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  createTheme,
  Box,
  ThemeProvider,
  Divider,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CustomSlider from "../../component/Slider/CustomSlider";
import "./UserDashboardSide.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import logo_2 from "../../assets/logo_2.png";
import save_25 from "../../assets/save_25.png";
import jack from "../../assets/jack.png";
import dollar from "../../assets/dollar.png";
import calender_board from "../../assets/calender_board.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./UserDashboardSide.css";

import Input from "../../component/common/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { get_services } from "../../services/services";
import { useNavigate } from "react-router-dom";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { imgUrl } from "../../utils/imageUrl";
import useWindowDimensions from "../../utils/hooks/windowDimensions";
import CustomModal from "../../component/common/CustomModal/CustomModal";
import ButtonComp from "../../component/common/ButtonComp";
import { cancelSubscriptionAsync } from "../../services/SubscriptionService";
import { RxCross2 } from "react-icons/rx";
import { service_booking_keys, SERVICE_DETAIL_PAGE_CONSTANT, steps_constants } from "../../store/constants";
import { setCancelSubscriptionSatus } from "../../store/slices/subscription_slice";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { setJobsImages } from "../../store/slices/services_slice";

const DashboardUserSide = () => {
  const { width } = useWindowDimensions();
  const swiperRef = useRef(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { get_all_services_status, get_all_services_data, get_vendor_data } =
    useSelector((state) => state.serviceManage);
  const { user, get_user_profile_data } = useSelector(
    (state) => state.userAuth
  );
  const { cancel_subscription_status } = useSelector(
    (state) => state.subscriptionSlice
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cancelSubscribeLoader =
    cancel_subscription_status === asyncStatus.LOADING;

  const getServiceLoader = get_all_services_status === asyncStatus.LOADING;

  const _goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const _goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const isEmailVerified = user?.email_verified_at;
    if (!isEmailVerified) {
      navigate("/vendor-check-phone");
    }
    dispatch(get_services());
  }, []);

  const _handleCancel = () => {
    setIsOpenModal(!isOpenModal);
  };

  const _handleNavigateMemberShip = () => {
    navigate("/member-ship");
    localStorage.setItem(SERVICE_DETAIL_PAGE_CONSTANT.from, "FROM_DASHBOARD");
  };

  const _handleClick = (item) => {
    navigate(`/service-details/${item}`);
    dispatch(setJobsSteps({ value: null }));
    localStorage.removeItem(steps_constants.JOBS_STEP);
    localStorage.removeItem(service_booking_keys.DATE);
    localStorage.removeItem(service_booking_keys.TIME);
    localStorage.removeItem(service_booking_keys.DESCRIPTION);
    localStorage.removeItem(service_booking_keys.LOCATION);
    localStorage.removeItem(service_booking_keys.PRICE);
    localStorage.removeItem(service_booking_keys.IMAGES);
  };

  const _handleCancelSubscribe = () => {
    dispatch(cancelSubscriptionAsync());
  };

  useEffect(() => {
    if (cancel_subscription_status === asyncStatus.SUCCEEDED) {
      dispatch(setCancelSubscriptionSatus());
      setIsOpenModal(false);
      window.location.reload();
    }
  }, [cancel_subscription_status]);

  return (
    <Stack bgcolor={"#FAFAFA"}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Stack gap={3}>
          <Stack>
            <Typography className="mainHeading">Dashboard</Typography>
            <hr color={"#D1D1D1"} />
          </Stack>

          <Stack sx={{ width: "100%" }}>
            {getServiceLoader ? (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: "60vh", width: "100%" }}
              >
                <CircularProgress size={30} sx={{ color: themeOrange }} />
              </Stack>
            ) : get_all_services_data?.services?.length &&
              get_all_services_data?.services?.length === 0 ? (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: "60vh", width: "100%" }}
              >
                <Typography>No Services Found</Typography>
              </Stack>
            ) : (
              <Stack>
                {/* CAROUSEL SLIDER -----!> */}
                <Stack sx={{ width: "100%" }}>
                  <Typography my={1} className="mainHeading">
                    Popular Services
                  </Typography>
                  <CustomSlider />
                </Stack>

                {/* POPULAR SERVICES -----!> */}
                <Stack my={5}>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    direction={"row"}
                  >
                    <Typography className="mainHeading">
                      All Services
                    </Typography>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      gap={2}
                    >
                      {/* --------!> BACKWARD BUTTON */}
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "10px",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 2px 0px grey",
                          cursor: "pointer",
                        }}
                        onClick={_goToPrevSlide}
                      >
                        <MdOutlineKeyboardArrowLeft
                          style={{ color: "black", fontSize: "25px" }}
                        />
                      </Stack>
                      {/* --------!> FORWARD BUTTON */}
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "10px",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 2px 0px grey",
                          cursor: "pointer",
                        }}
                        onClick={_goToNextSlide}
                      >
                        <MdOutlineKeyboardArrowRight
                          style={{ color: "black", fontSize: "25px" }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      boxShadow: "0px 0px 2px 0px grey",
                      px: 1,
                      py: 1,
                      overflow: "auto",
                      mt: 1,
                    }}
                    gap={1}
                    className="slider_wrapper"
                  >
                    <Swiper
                      ref={swiperRef}
                      slidesPerView={
                        width >= 1100
                          ? 5
                          : width < 1100 && width > 900
                          ? 3.8
                          : width < 900 && width > 530
                          ? 3
                          : width < 530 && width > 460
                          ? 2.5
                          : width < 460 && width > 350
                          ? 1.8
                          : width < 350
                          ? 1
                          : 5
                      }
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      className="mySwiper"
                    >
                      {get_all_services_data?.services?.map(
                        ({ image, name, id,mobile_image,slug }) => {
                          return (
                            <SwiperSlide key={id}>
                              <Stack
                                onClick={() => _handleClick(slug)}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                sx={{
                                  backgroundColor: "#F1F1F1",
                                  borderRadius: "10px",
                                  height: "130px",
                                  width: "150px",
                                  resize: "none",
                                  flexShrink: "0",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                                gap={1.5}
                                className="slider_item"
                              >
                                <Stack
                                  sx={{
                                    height: "70px",
                                    width: "100%",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={`${imgUrl}${mobile_image || ""}`}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Stack>


                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    color: "black",
                                  }}
                                >
                                  {name || ""}
                                </Typography>
                              </Stack>
                            </SwiperSlide>
                          );
                        }
                      )}
                    </Swiper>
                  </Stack>
                </Stack>

                {/* MEMBERSHIP -----!> */}
                <Stack my={1} gap={3}>
                  <Stack>
                    <Typography className="mainHeading">MemberShip</Typography>
                    <hr color={"#D1D1D1"} />
                  </Stack>

                  <Stack
                    alignItems={"center"}
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    className="globleGradientBlue"
                  >
                    {/*   MEMBERSHIP DETAILS -----!> */}

                    <Grid container spacing={2}>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12} py={2}>
                        <Stack
                          alignItems={"center"}
                          justifyContent={"space-around"}
                          gap={4}
                          px={1}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"flex-end"}
                            justifyContent={"flex-end"}
                            flexWrap={"nowrap"}
                            p={2}
                          >
                            <img
                              src={logo_2}
                              style={{
                                height: width < 600 ? "40px" : "62px",
                                width: width < 600 ? "80px" : "145px",
                                objectFit: "contain",
                              }}
                              alt="Logo"
                            />
                            <Typography
                              className="user_dashboard_member_txt"
                              sx={{
                                fontSize: {
                                  xl: "45px !important",
                                  lg: "35px !important",
                                  md: "38px !important",
                                  sm: "35px !important",
                                  xs: "25px !important",
                                },
                                flexShrink: 0,
                              }}
                            >
                              Member
                            </Typography>
                          </Stack>

                          <Stack
                            className="user_dashboard_discount_border"
                            sx={{
                              width: {
                                xl: "80% !important",
                                lg: "100% !important",
                                md: "100% !important",
                                sm: "65% !important",
                                xs: "100% !important",
                              },
                            }}
                            px={2}
                            py={1}
                            color={"white"}
                          >
                            <Stack
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              sx={{
                                width: "100%",
                                gap: 1,
                              }}
                              flexWrap={"wrap"}
                            >
                              <Stack>
                                <Typography
                                  className="user_dashboard_save_25"
                                  sx={{
                                    fontSize: {
                                      xl: "20px !important",
                                      lg: "20px !important",
                                      md: "17px !important",
                                      sm: "14px !important",
                                      xs: "14px !important",
                                    },
                                  }}
                                >
                                  Save $25
                                </Typography>
                                <Typography
                                  className="user_dashboard_on_every_done_txt"
                                  sx={{
                                    fontSize: {
                                      xl: "15px !important",
                                      lg: "15px !important",
                                      md: "12px !important",
                                      sm: "12px !important",
                                      xs: "12px !important",
                                    },
                                  }}
                                >
                                  on every Done job
                                </Typography>
                              </Stack>
                              <Typography className="user_dashboard_for_10_month">
                                just for $10/month
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Stack
                          justifyContent={"center"}
                          alignItems={{
                            xl: "flex-start",
                            lg: "flex-start",
                            md: "flex-start",
                            sm: "flex-start",
                            xs: "center",
                          }}
                          gap={3}
                          px={2}
                          py={1}
                          sx={{ height: "100%" }}
                        >
                          <Button
                            onClick={() =>
                              user?.subscription_id
                                ? _handleCancel()
                                : _handleNavigateMemberShip()
                            }
                            variant="contained"
                            sx={{
                              borderRadius: "10px",
                              fontSize: "15px",
                              fontWeight: "400",
                              backgroundColor: "#F15A24",
                              textTransform: "inherit",
                              ":hover": { backgroundColor: "#F15A24" },
                            }}
                          >
                            {user?.subscription_id
                              ? "Cancel Your Subscription"
                              : "Upgrade to Done for $10/month"}
                          </Button>
                          {/* 
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                            flexWrap={"wrap"}
                          >
                            <Typography
                              className="subHeading"
                              sx={{
                                fontWeight: "500 !important",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Or upgrade with a &nbsp;
                            </Typography>
                            <Typography
                              className="subHeading"
                              sx={{
                                fontWeight: "700 !important",
                                color: "white",
                                textAlign: "left",
                              }}
                            >
                              Membership Code
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              borderRadius: "10px",
                              overflow: "hidden",
                              height: "50px",
                              width: "70%",
                            }}
                          >
                            <Input
                              placeholder={"Enter your membership code"}
                              style={{
                                padding: "0px",
                                margin: "0px",
                                border: "0px",
                                width: "70%",
                                height: "100%",
                                backgroundColor: "#FFFFFF1A",
                                borderRadius: "0px",
                                paddingLeft: "15px",
                                fontSize: "10px",
                                fontWeight: "200",
                              }}
                            />
                            <Button
                              variant="contained"
                              sx={{
                                ":hover": { backgroundColor: "#F15A24" },
                                backgroundColor: "#F15A24",
                                height: "100%",
                                width: "30%",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                            >
                              Join
                            </Button>
                          </Stack> */}
                          {/* >>>>>>>>> CANCEL SUBSCRIPTION MODAL */}
                          <CustomModal
                            isOpen={isOpenModal}
                            setIsOpen={() => setIsOpenModal(!isOpenModal)}
                          >
                            <Stack
                              sx={{
                                overflow: "auto",
                                backgroundColor: "white",
                                borderRadius: "10px",
                              }}
                              alignItems={"center"}
                              p={1}
                            >
                              <Stack
                                sx={{ width: "100%" }}
                                alignItems={"flex-end"}
                              >
                                <IconButton
                                  onClick={() => setIsOpenModal(!isOpenModal)}
                                >
                                  <RxCross2 size={20} sx={{ color: "black" }} />
                                </IconButton>
                              </Stack>

                              <Stack gap={2} px={1} sx={{ width: "100%" }}>
                                <Typography
                                  sx={{
                                    color: "black",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                  }}
                                >
                                  Are you sure you want to cancel this
                                  subscription?
                                </Typography>

                                <Stack
                                  gap={2}
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"flex-end"}
                                >
                                  <ButtonComp
                                    onClick={_handleCancelSubscribe}
                                    label={
                                      cancelSubscribeLoader ? (
                                        <CircularProgress
                                          size={22}
                                          sx={{ color: "white" }}
                                        />
                                      ) : (
                                        "Yes"
                                      )
                                    }
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      borderRadius: "15px",
                                    }}
                                  />
                                  <ButtonComp
                                    onClick={() => setIsOpenModal(!isOpenModal)}
                                    label={"No"}
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      borderRadius: "15px",
                                    }}
                                  />
                                </Stack>
                              </Stack>
                            </Stack>
                          </CustomModal>
                        </Stack>
                      </Grid>

                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Stack
                          justifyContent={"center"}
                          gap={2}
                          px={2}
                          sx={{ height: "100%" }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                            sx={{ width: "100%" }}
                          >
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "5px",
                                backgroundColor: "#F15A24",
                                flexShrink: "0",
                              }}
                            >
                              {" "}
                              <img
                                src={dollar}
                                style={{ height: "25px", width: "25px" }}
                              />{" "}
                            </Stack>
                            <Stack alignItems={"center"}>
                              <Typography
                                className="mainPara"
                                sx={{ color: "white" }}
                              >
                                Save $25 on every Done Job
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                            sx={{ width: "100%" }}
                          >
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "5px",
                                backgroundColor: "#F15A24",
                                flexShrink: "0",
                              }}
                            >
                              {" "}
                              <img
                                src={jack}
                                style={{ height: "25px", width: "25px" }}
                              />{" "}
                            </Stack>
                            <Stack alignItems={"center"}>
                              <Typography
                                className="mainPara"
                                sx={{ color: "white" }}
                              >
                                Warranty Extension from 30 to 60 days
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                            sx={{ width: "100%" }}
                          >
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "5px",
                                backgroundColor: "#F15A24",
                                flexShrink: "0",
                              }}
                            >
                              {" "}
                              <img
                                src={calender_board}
                                style={{ height: "25px", width: "25px" }}
                              />{" "}
                            </Stack>
                            <Stack alignItems={"center"}>
                              <Typography
                                className="mainPara"
                                sx={{ color: "white" }}
                              >
                                Cancel membership anytime (no fee or panalty)
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>

                    {/*   TERMS AND CONDITIONS -----!> */}
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexWrap={"wrap"}
                      sx={{ width: "100%" }}
                      px={1}
                      py={2}
                    >
                      <Typography
                        variant="caption"
                        className="subpara"
                        sx={{
                          color: "white",
                          fontSize: {
                            xl: "17px !important",
                            lg: "17px !important",
                            md: "15px !important",
                            sm: "15px !important",
                            xs: "13px !important",
                          },
                          fontWeight: "400 !important",
                        }}
                      >
                        The $25 discount applies once per job. Done Member
                        continue for $10/Month untill cancelled. See &nbsp;
                        <a
                          style={{
                            color: "white",
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "17px !important",
                            fontWeight: "400 !important",
                          }}
                          onClick={() => navigate("/support")}
                        >
                          Done Terms & Conditions.
                        </a>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default DashboardUserSide;
