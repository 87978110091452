import React from "react";
import { useSelector } from "react-redux";
import { asyncStatus } from "../../../utils/asyncStatus";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { themeOrange } from "../../../utils/colorTheme";
import { CustomTable } from "../../common/customTable/CustomTable";

const CompletedVendorJobsTab = () => {
  const {
    get_job_by_vendor_status,
    get_job_by_vendor_data,
    get_job_by_vendor_error,
  } = useSelector((state) => state.serviceManage);
  const jobDoneLoader = get_job_by_vendor_status === asyncStatus.LOADING;

  const completedJobs = get_job_by_vendor_data?.filter(
    (job) => job.status === "Completed" || job.status === "Aprroval-Waiting"
  );
  return (
    <div>
      {jobDoneLoader ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "60vh", width: "100%" }}
        >
          <CircularProgress size={30} sx={{ color: themeOrange }} />
        </Stack>
      ) : !completedJobs?.length && completedJobs?.length === 0 ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "60vh", width: "100%" }}
        >
          <Typography>No Jobs Found</Typography>
        </Stack>
      ) : (
        <CustomTable data={completedJobs} />
      )}
    </div>
  );
};

export default CompletedVendorJobsTab;
