import React, { useEffect } from "react";
import { Container, Stack, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetAllJobsAsync } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import CustomTabs from "../../component/common/CustomTabs/CustomTabs";
import CompletedJobsTab from "../../component/MyJobTabs/CompletedJobTabs/CompletedJobsTab";
import ActiveJobsTab from "../../component/MyJobTabs/ActiveJobsTab/ActiveJobsTab";
import DraftedJobsTab from "../../component/MyJobTabs/DraftedJobsTab/DraftedJobsTab";
import WaitingJobsTab from "../../component/MyJobTabs/WaitingJobTabs/WaitingJobsTab";
const MyJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { get_all_jobs_status, get_all_jobs_data } = useSelector(
    (state) => state.serviceManage
  );

  
  const jobLoader = get_all_jobs_status === asyncStatus.LOADING;
  
  const tabs = [
    { label: "Active Jobs", content: <ActiveJobsTab jobLoader={jobLoader} /> },
    {
      label: "Completed Jobs",
      content: <CompletedJobsTab jobLoader={jobLoader} />,
    },
    {
      label: "Pending Jobs",
      content: <DraftedJobsTab jobLoader={jobLoader} />,
    },
    {
      label: "Vendor Approval",
      content: <WaitingJobsTab jobLoader={jobLoader} />,
    },
  ];
  
  useEffect(() => {
    dispatch(GetAllJobsAsync());
  }, []);
  return (
    <Stack bgcolor={"#FAFAFA"}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Stack gap={1} pb={2}>
          <Stack>
            <Typography className="mainHeading">My Jobs</Typography>
            <hr color={"#D1D1D1"} />
          </Stack>

          {jobLoader ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "60vh", width: "100%" }}
            >
              <CircularProgress size={30} sx={{ color: themeOrange }} />
            </Stack>
          ) : get_all_jobs_data?.length === 0 ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "60vh", width: "100%" }}
            >
              <Typography>No Jobs Found</Typography>
            </Stack>
          ) : (
            <CustomTabs tabs={tabs} />
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default MyJobs;
