import { useNavigate } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
import "../index.css";
import Navbar from "../component/navbar/Navbar";
import HeroSection from "../screens/FirstSection/HeroSection";
import ThirdSection from "../screens/ThirdSection/ThirdSection";
import SecondSection from "../screens/SecondSection/SecondSection";
import FiftSection from "../screens/FiftSection/FiftSection";
import FourthSection from "../screens/FourthSection/FourthSection";
import Footer from "../screens/Footer/Footer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { asyncStatus } from "../utils/asyncStatus";
import HowWorksSection from "../screens/HowWorksSection/HowWorksSection";
import JobAcceptedSection from "../screens/JobAcceptedSection/JobAcceptedSection";

function Home() {
  const navigate = useNavigate();
  const { user_login_status, user } = useSelector((state) => state.userAuth);
  console.log("!user?.email_verified_at", user?.email_verified_at);

  // useEffect(() => {
  //   if (user_login_status === asyncStatus.SUCCEEDED) {
  //     // navigate("/");
  //     // dispatch(setIdleStatus(setIdleStatus));

  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div>
      <Navbar />
      <HeroSection />
      {/* <JobAcceptedSection /> */}
      <SecondSection />
      <HowWorksSection />
      <ThirdSection />
      <FiftSection />
      <FourthSection />
      <Footer />
    </div>
  );
}

export default Home;
